<!-- eslint-disable -->
<script setup>
import { useFooterSectionStore } from '@/stores/footer_sections';
import { ref, onMounted, watch } from "vue";
import moment from 'moment';

const footerSectionStore = useFooterSectionStore();
onMounted(() => {

console.log(footerSectionStore.getFooterSections);
})

</script>
<template>
	<template v-for="section in footerSectionStore.getFooterSections" :key="section.id">
		<template v-if="section.name === 'Social'">
				<div class="footer-inner social-section">
					<h3 class="social-title"></h3>
					<div class="social-grid">
						<template v-for="item in section.items" :key="item.id">
							<a :href="item.redirect_url" target="_blank" class="social-icon">
								<img :src="item.media_url" alt="Social Media Icon">
							</a>
						</template>
					</div>
				</div>
		</template>
	</template>
</template>
<style scoped> 
 @media screen and (min-width: 320px) and (max-width: 800px) {
	.footer-desktop {
		display:none;
	}
	.social-icon img{
		max-width: 60px;
	}
	.footer-outer.dynamic {
		justify-content:center;
	}
	.footer-inner.contact-container {
		width:100%;
	}
	h6.social-title {
		font-size:14px;
		padding-bottom:5px;
	}
}
@media screen and (min-width: 801px) and (max-width: 3000px) {
	.footer-mobile {
		display:none;
	}
}
@media screen and (min-width: 650px) and (max-width:800px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr; 
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr 1fr 1fr!important;
		gap:35px!important;
	}
	.desc-section {
		width:100%!important;
		padding-right: 70px;
		padding-left: 70px;
	}
}
@media screen and (min-width: 450px) and (max-width:649px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr; 
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr 1fr!important;
		gap:35px!important;
	}
	
}
@media screen and (min-width: 580px) and (max-width:649px) {
	.desc-section {
		width:100%!important;
		padding-right: 70px;
		padding-left: 70px;
	}
}
@media screen and (min-width: 542px) and (max-width:579px) {
	.desc-section {
		width:100%!important;
		padding-right: 60px;
		padding-left: 60px;
	}
}
@media screen and (min-width: 449px) and (max-width:541px) {
	.desc-section {
		width:100%!important;
		padding-right: 40px;
		padding-left: 40px;
	}
}
@media screen and (min-width: 347px) and (max-width:449px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr; 
		column-gap:30px;
		row-gap:10px;
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr!important;
		column-gap:35px!important;
	}
	.desc-section {
		width:100%!important;
		padding-right: 30px;
		padding-left: 30px;
	}
	a.custom-font{
		margin-right:30px;
	}
	h6.social-title.payment {
		padding-left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width:346px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr; 
		column-gap:15px;
		row-gap:10px;
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr!important;
		column-gap:20px!important;
	}
	.desc-section {
		width:100%!important;
		padding-right: 20px;
		padding-left: 20px;
	}
	a.custom-font{
		margin-right:30px;
	}
	h6.social-title.payment {
		padding-left: 10px;
	}
}
.contact-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* Four equal columns */
	gap: 10px; /* Adjust the gap between columns as needed */
	justify-items:center;
}
@media screen and (min-width: 1053px) and (max-width: 1359px) {
	.contact-grid {
		grid-template-columns: 1fr 1fr 1fr
	}
}
@media screen and (min-width: 801px) and (max-width: 1052px) { 
	.contact-grid {
		grid-template-columns: 1fr 1fr
	}
	a.custom-font{
		margin-right:30px;
	}
}
.footer-inner.contact-container {
	margin-top:10px;
}

p.reach_us_by_phone_call{
	color: #63A246;
	font-weight: bold;
}
p.ussd_code{
	color: #63A246;
	font-weight: bold;
	border: 1px solid;
	border-radius: 5px;
	padding: 5px 10px;
	width: 100px;
}

a.custom-font {
	display: inline-block;
	padding: 10px 20px;
	background-color: #FFBB02;
	color: #202833;
	text-decoration: none;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size:13px;
}
a.free-call {
	display: inline-block;
	padding: 10px 8px;
	background-color: transparent;
	text-decoration: none;
	border: 1px solid;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	margin-top: 22px;
	font-size:13px;
}
@media screen and (min-width: 1000px) and (max-width: 3000px) {
	a.free-call {
		margin-top: 22px;
	}
}
.social-icon img{
	max-width: 50px;
	padding:10px;
}
h6.social-title {
	color: white !important;
}
.browser-time {
	background-color: gray;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	/* font-weight: bold; */
	display: inline-block;
	height:100%
}

.social-grid {
	display: flex;
	align-items: center;
}

.desc-section {
	display: flex;
	flex-direction: column;
	width: 50%
}
.copyright-text p {
	font-size: 12px;
	color: #878a90;
}
p.desc {
	font-size: 12px;
	color: #878A90;
}
.logo {
	margin-right:85%
}
.footer-outer.dynamic {
	padding-bottom: 20px;
	padding-top: 20px;
}
.footer-outer.descr-contact {
	padding-bottom: 20px;
	padding-top: 20px;
	justify-content:unset;
}
.payments-icon img{
	max-width: 150px;
	padding:10px;
}

.payments-section {
	padding-top: 20px;
	display: flex;
	justify-content: center;
}
</style>