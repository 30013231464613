<!-- eslint-disable -->
<script setup>
import { useCustomerStore } from '@/stores/customer.js';
import { useCountriesStore } from '@/stores/countries.js';
import { useAccountDetailsStore } from '@/stores/account_details.js';
import { onMounted, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAccountStore } from '@/stores/account';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2'

const accountStore = useAccountStore();
const router = useRouter();
const customerStore = useCustomerStore();
const accountDetailsStore = useAccountDetailsStore();
const { t } = useI18n();

const selectedCountryPrefix = ref(customerStore.getCustomer.mobile_country_id);
const form_data = ref({ mobile_country_id: null, mobile_number: null });
const reset_form = ref({ mobile_country_id: null, mobile_number: null });
const error_data = ref({});
const loading = ref(false);
const disabled_button = ref(true);
const active_tab = ref('contact_details');

const alert_message = ref('');

const goToRoute = (route_path) => {
	if (route_path) router.push({ path: route_path });
	else router.push({ name: 'landing' });
}

const resetForm = () => {
	form_data.value.mobile_country_id = reset_form.value.mobile_country_id;
	form_data.value.mobile_number = reset_form.value.mobile_number;
	error_data.value = '';
}

const updateData = () => {
	loading.value = true
	accountDetailsStore.updateAccountDetails(form_data.value).then((response) => {
		selectedCountryPrefix.value = response.data.mobile_country_id;
		reset_form.value = { mobile_country_id: response.data.mobile_country_id, mobile_number: response.data.mobile_number };
		alert_message.value = t('account.account_details.success_message');
		fireSwal('success', alert_message.value);
		loading.value = false;
		disabled_button.value = true;
	}).catch((error) => {
		error_data.value = error.data;
		disabled_button.value = true;
		loading.value = false;
	});
}

function isMobileDevice() {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return true;
	}

	return false;
}

const showButtons = () => {
	if (isMobileDevice()) {
		if (active_tab.value === 'contact_details') return true
		return false
	} else {
		active_tab.value = 'personal_info'
		return true
	}
}

const fireSwal = (alert_type, alert_message) => {
	Swal.fire({
		text: alert_message,
		icon: alert_type,
		confirmButtonText: alert_type == 'success' ? t('account.register.close') : t('general.try_again'),
	});
}

watch(() => form_data.value.mobile_country_id, (newData, oldData) => {
	if(newData != customerStore.getCustomer.mobile_country_id) disabled_button.value = false
	else disabled_button.value = true
})
watch(() => form_data.value.mobile_number, (newData, oldData) => {
	if(newData != customerStore.getCustomer.mobile_number) {
		if (error_data.value != null) error_data.value = {}
		disabled_button.value = false
	} else disabled_button.value = true
})

onMounted(() => {
	form_data.value = { mobile_country_id: customerStore.getCustomer.mobile_country_id, mobile_number: customerStore.getCustomer.mobile_number }
	reset_form.value = { mobile_country_id: customerStore.getCustomer.mobile_country_id, mobile_number: customerStore.getCustomer.mobile_number }
})
</script>
<template>
		<div class="dashboard-tab-content">
			<div class="tab-content-outer">
				<div class="dash-inner-tabs">
				</div>

				<div class="dash-content-main account-detail-main" id="account-detail">
					<div class="dash-content-outer">
						<div class="dash-main-content">
							<div class="title">
								<h3>{{$t('account.account_details.title')}}</h3>
							</div>
							<div class="tab tab-mobile-dash">
								<button class="inner-tab-item" :class="active_tab == 'personal_info' ? 'active' : ''" @click="active_tab='personal_info'">{{$t('account.account_details.personal')}}</button>
								<button class="inner-tab-item" :class="active_tab == 'contact_details' ? 'active' : ''" @click="active_tab='contact_details'">{{$t('account.account_details.contact')}}</button>
							</div>

							<div class="tabs-outer">
								<div class="tabs-inner" :class="active_tab == 'personal_info' ? 'active' : ''">
									<div class="title">
										<h6>{{$t('account.account_details.personal')}}</h6>
									</div>
									<div class="dashboard-form register-inner-form">
										<div class="register-form-field">
											<label>{{$t('account.account_details.name')}}</label>
											<input readonly class="form-input" :placeholder="$t('account.account_details.name')" type="text" v-model="customerStore.getCustomer.firstname" />
										</div>

										<div class="register-form-field">
											<label>{{$t('account.account_details.surname')}}</label>
											<input readonly class="form-input" :placeholder="$t('account.account_details.surname')" type="text" v-model="customerStore.getCustomer.lastname" />
										</div>
										<div class="register-form-field">
											<label>
												{{$t('account.account_details.date_of_birth')}}</label>
											<input readonly class="form-input" v-model="customerStore.getCustomer.dob" />
										</div>

										<div class="register-form-field">
											<label>
												{{$t('account.account_details.id')}}</label>
											<input readonly class="form-input" :placeholder="$t('account.account_details.id')" type="text" v-model="customerStore.getCustomer.national_id_number" />
										</div>
										<div class="register-form-field">
											<label>{{$t('account.account_details.gender')}}</label>
											<select name="" class="form-input form-control" id="gender" v-model="customerStore.getCustomer.gender" disabled>
												<option value="" disabled>{{$t('account.account_details.gender')}}</option>
												<option value="male">{{ $t('general.male') }}</option>
												<option value="female">{{ $t('general.female') }}</option>
											</select>
										</div>
									</div>
								</div>

								<div class="tabs-inner" :class="active_tab == 'contact_details' ? 'active' : ''">
									<div class="title">
										<h6>{{$t('account.account_details.contact')}}</h6>
									</div>
									<div class="dashboard-form register-inner-form">
										<div class="register-form-field">
											<label>{{$t('account.register.email_address')}}</label>
											<input readonly class="form-input" :placeholder="$t('account.register.email_address')" type="email" v-model="customerStore.getCustomer.email" />
										</div>

										<div class="register-form-field">
											<label>{{$t('account.account_details.number')}}</label>
											<div class="phone-field">
												<select v-model="form_data.mobile_country_id" name="mobile_prefix" class="form-input form-control select-not-read-only" id="address-country" required>
													<option v-for="country in useCountriesStore().getCountries" :value="country.id" :key="country.id">
														+{{ country.mobile_prefix }}
													</option>
												</select>
												<input v-model="form_data.mobile_number" type="tel" class="form-input" maxlength="15" required style="color: white;" />
											</div>
											<template v-if="error_data.errors != null">
												<div class="register-form-list" >
													<span style="color: red;" v-for="error in error_data.errors.mobile_number" :key="error">{{ error }}</span>
												</div>
											</template>
										</div>

										<div class="register-form-field">
											<label>{{$t('account.account_details.country')}}</label>
											<input readonly class="form-input" v-model="customerStore.getCustomer.customer_residence.country.name" name="country" />

										</div>

										<div class="register-form-field">
											<label> {{$t('account.account_details.address')}}</label>
											<input readonly class="form-input" type="text" name="city" v-model="customerStore.getCustomer.customer_residence.street_name" />

										</div>

										<div class="register-form-field">
											<label>
												{{$t('account.account_details.city')}}</label>
											<input readonly class="form-input" type="text" name="city" v-model="customerStore.getCustomer.customer_residence.city" />
										</div>

										<div class="register-form-field">
											<label>
												{{$t('account.account_details.postcode')}}</label>
											<input readonly class="form-input" type="text" name="city" v-model="customerStore.getCustomer.customer_residence.post_code" />
										</div>

									</div>
								</div>
							</div>
						</div>

						<div v-if="showButtons()" class="dashboard-footer">
							<div class="dash-btns">
								<button class="btn" type="button" @click="resetForm()">{{$t('general.cancel')}}</button>
							</div>
							<div class="dash-btns">
								<button class="btn btn-secondary" :class="{'disabled-button': disabled_button}" type="button" @click="updateData()" v-if="!loading">{{$t('general.update')}}</button>
								<button class="btn btn-secondary" type="button" v-else><div class="loader-main"></div></button>
							</div>
						</div>
					</div>
					<div class="close-btn" @click="goToRoute(accountStore.getPreviousRoute)">
						<a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="21.414" viewBox="0 0 21.414 21.414">
								<g id="close" transform="translate(0.707 0.707)">
									<line id="Line_2" data-name="Line 2" x2="20" y2="20" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1" />
									<line id="Line_3" data-name="Line 3" x1="20" y2="20" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1" />
								</g>
							</svg>
						</a>
					</div>
				</div>
			</div>
		</div>
</template>

<style scoped>
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dashboard-main .dashboard-outer .dash-mobile-title {
		padding:unset;
		padding-bottom: 10px;
		/* padding-top: 10px; */
	}
}
.disabled-button {
	background: transparent;
	pointer-events: none;
	border: 1px solid #4E545D;
	color: #4E545D;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .close-btn {
	right: unset!important;
	left: 50px!important;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .register-inner-form select {
	background-position: 10%;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .dashboard-footer .dash-btns:last-child {
	text-align: left;
}
</style>
