<!-- eslint-disable -->
<script setup>
import NavigateTimeLimits from './NavigateTimeLimits.vue';
import { onMounted, ref, computed, watch, nextTick } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';

// Define store
const customerStore = useCustomerStore();
const { t } = useI18n();

// Define variables
const loading = ref(false);
const success_message = ref(null);
const errors = ref({});
const alert_message = ref(null);

const time_limit_per_day_choice = ref(null);
const custom_daily_amount = ref(null);
const time_limit_per_week_choice = ref(null);
const custom_weekly_amount = ref(null);
const time_limit_per_month_choice = ref(null);
const custom_monthly_amount = ref(null);
const form_edited = ref(false);

const successfully_changed_daily = ref(false);
const successfully_changed_weekly = ref(false);
const successfully_changed_monthly = ref(false);
const unsuccessfully_changed_daily = ref(false);
const unsuccessfully_changed_weekly = ref(false);
const unsuccessfully_changed_monthly = ref(false);

const validateStake = (event) => {
	if ((event.keyCode < 48 || event.keyCode > 57) || event.keyCode === 46) {
	event.preventDefault();
	}
}

const customerTimeLimitsDaily = computed(() => {
	if (customerStore.getCustomer.time_limits && customerStore.getCustomer.time_limits.daily) {
		return customerStore.getCustomer.time_limits.daily;
	}

	return null;
});

const customerTimeLimitsWeekly = computed(() => {
	if (customerStore.getCustomer.time_limits && customerStore.getCustomer.time_limits.weekly) {
		return customerStore.getCustomer.time_limits.weekly;
	}

	return null;
});

const customerTimeLimitsMonthly = computed(() => {
	if (customerStore.getCustomer.time_limits && customerStore.getCustomer.time_limits.monthly) {
		return customerStore.getCustomer.time_limits.monthly;
	}

	return null;
});

// Get error messages from an errors object.
const getErrorMessages = (errors) => {
	if (errors) {
		return Object.keys(errors).map(key => errors[key]).join(', ');
	}
	return '';
};

const update = () => {
	if (form_edited.value == false) return;

	successfully_changed_daily.value = false;
	successfully_changed_weekly.value = false;
	successfully_changed_monthly.value = false;
	unsuccessfully_changed_daily.value = false;
	unsuccessfully_changed_weekly.value = false;
	unsuccessfully_changed_monthly.value = false;

	errors.value = {};
	success_message.value = null;

	if (customerTimeLimitsDaily.value != time_limit_per_day_choice.value || customerTimeLimitsWeekly.value != time_limit_per_week_choice.value || customerTimeLimitsMonthly.value != time_limit_per_month_choice.value) {
		loading.value = true;

		// Create payload
		let body = {
			time_limit_per_day_choice: time_limit_per_day_choice.value,
			custom_daily_amount: custom_daily_amount.value,
			time_limit_per_week_choice: time_limit_per_week_choice.value,
			custom_weekly_amount: custom_weekly_amount.value,
			time_limit_per_month_choice: time_limit_per_month_choice.value,
			custom_monthly_amount: custom_monthly_amount.value,
		}

		// Send request
		customerStore.updateCustomerProtectionTimeLimits(body).then((response) => {
			success_message.value = response.data.message;
			fireSwal('success', response.data.message);

			// Fetch customer
			customerStore.fetchCustomer();

			loading.value = false;

			// Initialize variables based on the response data
			let responseDataUpdated = response.data.updated;
			let responseDataErrors = response.data.errors;

			// Check if the updates were successful or not for daily, weekly, and monthly changes
			if (responseDataUpdated) {
				if (responseDataUpdated.hasOwnProperty('per_day')) {
					successfully_changed_daily.value = responseDataUpdated.per_day;
				} else {
					successfully_changed_daily.value = false;
				}

				if (responseDataUpdated.hasOwnProperty('per_week')) {
					successfully_changed_weekly.value = responseDataUpdated.per_week;
				} else {
					successfully_changed_weekly.value = false;
				}

				if (responseDataUpdated.hasOwnProperty('per_month')) {
					successfully_changed_monthly.value = responseDataUpdated.per_month;
				} else {
					successfully_changed_monthly.value = false;
				}
			}

			// Check if there were any errors during the update process for daily, weekly, and monthly changes
			if (responseDataErrors) {
				if (responseDataErrors.hasOwnProperty('time_limit_per_day_choice')) {
					unsuccessfully_changed_daily.value = true;
				} else {
					unsuccessfully_changed_daily.value = false;
				}

				if (responseDataErrors.hasOwnProperty('time_limit_per_week_choice')) {
					unsuccessfully_changed_weekly.value = true;
				} else {
					unsuccessfully_changed_weekly.value = false;
				}

				if (responseDataErrors.hasOwnProperty('time_limit_per_month_choice')) {
					unsuccessfully_changed_monthly.value = true;
				} else {
					unsuccessfully_changed_monthly.value = false;
				}

				errors.value = responseDataErrors;
			}

			form_edited.value = false;

			// show different popup-alert when you have both error and success messages
			if ((Object.keys(errors.value).length > 0 && success_message.value !== null)) {
				alert_message.value = 'Success: ' + response.data.message + ', Error: ' + getErrorMessages(response.data.errors);
				fireSwal('info', alert_message.value);
			}
		}).catch((error) => {
			success_message.value = null;
			loading.value = false;
			let error_messages = Object.keys(error.data?.errors).map(key => error.data?.errors[key]);
			let message = error_messages.length > 0 ? error_messages.join(', ') : error.data?.message;
			alert_message.value = message;
			errors.value = error.data.errors;

			errors.value = error.data.hasOwnProperty('errors') ? error.data.errors : error.data;

			unsuccessfully_changed_daily.value = errors.value.hasOwnProperty('time_limit_per_day_choice');
			unsuccessfully_changed_weekly.value = errors.value.hasOwnProperty('time_limit_per_week_choice');
			unsuccessfully_changed_monthly.value = errors.value.hasOwnProperty('time_limit_per_month_choice');

			form_edited.value = false;
			fireSwal('error', alert_message.value);
		});
	}
}

const formatValue = (option) => {
	if (option['type'] == 'infinite' || option['type'] == 'no limit')
		return option['type'].charAt(0).toUpperCase() + option['type'].slice(1);

	return option['value'] + ' ' + option['type'].charAt(0).toUpperCase() + option['type'].slice(1);
}

watch(success_message, (newValue, oldValue) => {
	if (newValue != null) {
		setTimeout(() => {
			success_message.value = null;
		}, 3000);
	}
});

watch(errors, (newValue, oldValue) => {
	if (Object.keys(newValue).length > 0) {
		setTimeout(() => {
			errors.value = {};
		}, 3000);
	}
});

// Daily fields watchers - Handle form changes and change update button enabled state
watch(time_limit_per_day_choice, (newValue, oldValue) => {
	// Get default choices
	const default_choices = customerStore.customerProtectionSectionChoices('time_limits');

	if (customerTimeLimitsDaily) {
		// Get current daily limit
		let daily_value = customerTimeLimitsDaily.value ? customerTimeLimitsDaily.value.value : null;

		// Get default daily limit
		var daily_limit_default = _.filter(default_choices, { value: daily_value });

		// Check if the current daily limit is the same as the default daily limit
		if (newValue != daily_value) form_edited.value = true;
		else form_edited.value = false;

		// Reset custom amount if needed
		if (daily_limit_default.length == 0 && time_limit_per_day_choice.value == 'other') {
			custom_daily_amount.value = daily_value;
		} else {
			custom_daily_amount.value = null;
		}
	}
});

watch(custom_daily_amount, (newValue, oldValue) => {
	// Get default choices
	const default_choices = customerStore.customerProtectionSectionChoices('time_limits');

	if (customerTimeLimitsDaily) {
		// Get current daily limit
		let daily_value = customerTimeLimitsDaily.value ? customerTimeLimitsDaily.value.value : null;

		// Get default daily limit
		var daily_limit_default = _.filter(default_choices, { value: daily_value });

		// Check if daily value is the same as the newValue
		if (daily_value != newValue) form_edited.value = true;
		else form_edited.value = false;
	}
});

// Weekly fields watchers - Handle form changes and change update button enabled state
watch(time_limit_per_week_choice, (newValue, oldValue) => {
	// Get default choices
	const default_choices = customerStore.customerProtectionSectionChoices('time_limits');

	if (customerTimeLimitsWeekly) {
		// Get current weekly limit
		let weekly_value = customerTimeLimitsWeekly.value ? customerTimeLimitsWeekly.value.value : null;

		// Get default weekly limit
		var weekly_limit_default = _.filter(default_choices, { value: weekly_value });

		// Check if the current daily limit is the same as the default daily limit
		if (newValue != weekly_value) form_edited.value = true;
		else form_edited.value = false;

		// Reset custom amount if needed
		if (weekly_limit_default.length == 0 && time_limit_per_week_choice.value == 'other') {
			custom_weekly_amount.value = weekly_value;
		} else {
			custom_weekly_amount.value = null;
		}
	}
});

watch(custom_weekly_amount, (newValue, oldValue) => {
	// Get default choices
	const default_choices = customerStore.customerProtectionSectionChoices('time_limits');

	if (customerTimeLimitsWeekly) {
		// Get current weekly limit
		let weekly_value = customerTimeLimitsWeekly.value ? customerTimeLimitsWeekly.value.value : null;

		// Get default weekly limit
		var weekly_limit_default = _.filter(default_choices, { value: weekly_value });

		// Check if weekly value is the same as the newValue
		if (weekly_value != newValue) form_edited.value = true;
		else form_edited.value = false;
	}
});

// Monthly fields watchers - Handle form changes and change update button enabled state
watch(time_limit_per_month_choice, (newValue, oldValue) => {
	// Get default choices
	const default_choices = customerStore.customerProtectionSectionChoices('time_limits');

	if (customerTimeLimitsMonthly) {
		// Get current monthly limit
		let monthly_value = customerTimeLimitsMonthly.value ? customerTimeLimitsMonthly.value.value : null;

		// Get default monthly limit
		var monthly_limit_default = _.filter(default_choices, { value: monthly_value });

		// Check if the current daily limit is the same as the default daily limit
		if (newValue != monthly_value) form_edited.value = true;
		else form_edited.value = false;

		// Reset custom amount if needed
		if (monthly_limit_default.length == 0 && time_limit_per_month_choice.value == 'other') {
			custom_monthly_amount.value = monthly_value;
		} else {
			custom_monthly_amount.value = null;
		}
	}
});

watch(custom_monthly_amount, (newValue, oldValue) => {
	// Get default choices
	const default_choices = customerStore.customerProtectionSectionChoices('time_limits');

	if (customerTimeLimitsMonthly) {
		// Get current monthly limit
		let monthly_value = customerTimeLimitsMonthly.value ? customerTimeLimitsMonthly.value.value : null;

		// Get default monthly limit
		var monthly_limit_default = _.filter(default_choices, { value: monthly_value });

		// Check if monthly value is the same as the newValue
		if (monthly_value != newValue) form_edited.value = true;
		else form_edited.value = false;
	}
});

const loadData = () => {
	const default_choices = customerStore.customerProtectionSectionChoices('time_limits');

	if (customerTimeLimitsDaily) {
		let daily_value = customerTimeLimitsDaily.value ? customerTimeLimitsDaily.value.value : null;
		var daily_limit_default = _.filter(default_choices, { value: daily_value });

		if (daily_limit_default.length == 0) {
			time_limit_per_day_choice.value = 'other';
			custom_daily_amount.value = daily_value;
		} else {
			time_limit_per_day_choice.value = daily_value;
		}
	}

	if (customerTimeLimitsWeekly) {
		let weekly_value = customerTimeLimitsWeekly.value ? customerTimeLimitsWeekly.value.value : null;
		var weekly_limit_default = _.filter(default_choices, { value: weekly_value });

		if (weekly_limit_default.length == 0) {
			time_limit_per_week_choice.value = 'other';
			custom_weekly_amount.value = weekly_value;
		} else {
			time_limit_per_week_choice.value = weekly_value;
		}
	}

	if (customerTimeLimitsMonthly) {
		let monthly_value = customerTimeLimitsMonthly.value ? customerTimeLimitsMonthly.value.value : null;
		var monthly_limit_default = _.filter(default_choices, { value: monthly_value });

		if (monthly_limit_default.length == 0) {
			time_limit_per_month_choice.value = 'other';
			custom_monthly_amount.value = monthly_value;
		} else {
			time_limit_per_month_choice.value = monthly_value;
		}
	}

	form_edited.value = false;
}

const fireSwal = (alert_type, alert_message) => {
	Swal.fire({
		text: alert_message,
		icon: alert_type,
		confirmButtonText: alert_type == 'success' ? t('account.register.close') : t('general.try_again'),
	});
}

onMounted(() => {
	loadData();

	nextTick().then(() => {
		form_edited.value = false;
	});
});


</script>
<template>
	<div class="dash-content-main player-protection-main bcom-time-out-limits" id="prefrences">
		<div class="dash-content-outer">
			<div class="dash-main-content">
				<div class="title">
					<h3>{{$t('account.customer_protection.time_out_title')+' + ' + $t('account.customer_protection.limits')}}</h3>
				</div>

				<div class="player-protection-outer">
					<NavigateTimeLimits></NavigateTimeLimits>
					<div class="content-outer single-column time-out" id="login-limit">
						<div class="content-inner becom-time-limits">
							<div class="list-content">
								<div class="desc-content">
									<p>{{$t('account.customer_protection.time_limits_description_text')}}</p>
								</div>
								<div class="dashboard-form register-inner-form no-column">

									<div class="register-form-field select-field">
										<div class="label-container">
											<label>{{$t('account.customer_protection.time_limits_day')}}</label>
											<template v-if="customerStore.getCustomer.time_limits">
												<span v-if="customerStore.getCustomer.time_limits.daily.value == null"
													class="not-set-label">({{ $t('account.customer_protection.not_set') }})</span>
												<span v-else class="active-label">({{ $t('account.customer_protection.active') }})</span>
											</template>
										</div>

										<div class="inline">
											<select class="form-input" v-model="time_limit_per_day_choice">
												<option v-for="option in customerStore.customerProtectionSectionChoices('time_limits')"
													:key="option.value" :value="option.value"
													:selected="option.value == time_limit_per_day_choice?.value">
													{{ formatValue(option) }}
												</option>
												<option value="other">{{ $t('account.customer_protection.other') }}</option>
											</select>

											<svg v-if="successfully_changed_daily == true" class="icon-check-mark"
												xmlns="http://www.w3.org/2000/svg" width="24" height="24"
												viewBox="0 0 24 24">
												<path
													d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
											</svg>
											<svg v-if="unsuccessfully_changed_daily == true" class="icon-error"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" id="katman_1"
												x="0px" y="0px" viewBox="20 20 1436 1054"
												style="enable-background:new 0 0 1436 1054;" xml:space="preserve">
												<path
													d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224  c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0  c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z" />
											</svg>
										</div>

										<input v-if="time_limit_per_day_choice == 'other'" @keypress="validateStake"  v-model="custom_daily_amount" autocapitalize="off" autocomplete="off" autocorrect="off"
												inputmode="numeric"  min="0" step="1" type="number" class="form-input custom-value" :placeholder="$t('account.customer_protection.daily_amount_in_hours')">
									</div>

									<div class="register-form-field select-field">
										<div class="label-container">
											<label>{{$t('account.customer_protection.time_limits_week')}}</label>
											<template v-if="customerStore.getCustomer.time_limits">
												<span v-if="customerStore.getCustomer.time_limits.weekly.value == null"
													class="not-set-label">({{ $t('account.customer_protection.not_set') }})</span>
												<span v-else class="active-label">({{ $t('account.customer_protection.active') }})</span>
											</template>
										</div>

										<div class="inline">
											<select class="form-input" v-model="time_limit_per_week_choice">
												<option class="form-input"
													v-for="option in customerStore.customerProtectionSectionChoices('time_limits')"
													:key="option.value" :value="option.value"
													:selected="option.value == time_limit_per_week_choice?.value">
													{{ formatValue(option) }}
												</option>
												<option value="other">{{ $t('account.customer_protection.other') }}</option>
											</select>

											<svg v-if="successfully_changed_weekly == true" class="icon-check-mark"
												xmlns="http://www.w3.org/2000/svg" width="24" height="24"
												viewBox="0 0 24 24">
												<path
													d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
											</svg>

											<svg v-if="unsuccessfully_changed_weekly == true" class="icon-error"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" id="katman_1"
												x="0px" y="0px" viewBox="20 20 1436 1054"
												style="enable-background:new 0 0 1436 1054;" xml:space="preserve">
												<path
													d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224  c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0  c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z" />
											</svg>
										</div>
										<input v-if="time_limit_per_week_choice == 'other'"
												v-model="custom_weekly_amount" autocapitalize="off" autocomplete="off" autocorrect="off"
												@keypress="validateStake" inputmode="numeric" min="0" type="number" class="form-input custom-value"
												:placeholder="$t('account.customer_protection.weekly_amount_in_hours')">
									</div>

									<div class="register-form-field select-field">
										<div class="label-container">
											<label>{{$t('account.customer_protection.time_limits_month')}}</label>
											<template v-if="customerStore.getCustomer.time_limits">
												<span v-if="customerStore.getCustomer.time_limits.monthly.value == null"
													class="not-set-label">({{ $t('account.customer_protection.not_set') }})</span>
												<span v-else class="active-label">({{ $t('account.customer_protection.active') }})</span>
											</template>
										</div>

										<div class="inline">
											<select class="form-input" v-model="time_limit_per_month_choice">
												<option class="form-input"
													v-for="option in customerStore.customerProtectionSectionChoices('time_limits')"
													:key="option.value" :value="option.value"
													:selected="option.value == time_limit_per_month_choice?.value">
													{{ formatValue(option) }}
												</option>
												<option value="other">{{ $t('account.customer_protection.other') }}</option>
											</select>
											<svg v-if="successfully_changed_monthly == true" class="icon-check-mark"
												xmlns="http://www.w3.org/2000/svg" width="24" height="24"
												viewBox="0 0 24 24">
												<path
													d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
											</svg>

											<svg v-if="unsuccessfully_changed_monthly == true" class="icon-error"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" id="katman_1"
												x="0px" y="0px" viewBox="20 20 1436 1054"
												style="enable-background:new 0 0 1436 1054;" xml:space="preserve">
												<path
													d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224  c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0  c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z" />
											</svg>
										</div>

										<input v-if="time_limit_per_month_choice == 'other'"
											v-model="custom_monthly_amount" autocapitalize="off" autocomplete="off" autocorrect="off"
											@keypress="validateStake" inputmode="numeric"  min="0"  step="1" type="number" class="form-input custom-value"
											:placeholder="$t('account.customer_protection.monthly_amount_in_hours')">
									</div>

								</div>
							</div>
							<div class="list-instructions">
								<ul>
									<li>
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
											<g id="Group_223" data-name="Group 223" transform="translate(-566 -572.276)">
												<path id="Icon_awesome-info-circle" data-name="Icon awesome-info-circle" d="M6.562.563a6,6,0,1,0,6,6A6,6,0,0,0,6.562.563Zm0,2.661A1.016,1.016,0,1,1,5.546,4.24,1.016,1.016,0,0,1,6.562,3.224ZM7.917,9.369a.29.29,0,0,1-.29.29H5.5a.29.29,0,0,1-.29-.29V8.788A.29.29,0,0,1,5.5,8.5h.29V6.95H5.5a.29.29,0,0,1-.29-.29V6.079a.29.29,0,0,1,.29-.29H7.046a.29.29,0,0,1,.29.29V8.5h.29a.29.29,0,0,1,.29.29Z" transform="translate(565.438 571.713)" fill="#fff"></path>
											</g>
										</svg>
										<p>{{$t('account.customer_protection.time_limits_description_text_2')}}</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="dashboard-footer">
				<div class="dash-btns">
					<button type="button" class="btn" @click="loadData()">{{$t('general.cancel')}}</button>
				</div>
				<div class="dash-btns">
					<button type="button" class="btn btn-secondary" v-if="loading">
						<div class="loader-main"></div>
					</button>
					<button type="button" class="btn btn-secondary" v-if="!loading"  @click="update()" :class="{'disabled-button': form_edited == false }">{{$t('general.update')}}</button>
				</div>
			</div>
		</div>
		<div class="close-btn" @click="goToPreviousPage(accountStore.getPreviousRoute)">
			<a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="21.414" viewBox="0 0 21.414 21.414">
					<g id="close" transform="translate(0.707 0.707)">
						<line id="Line_2" data-name="Line 2" x2="20" y2="20" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1"></line>
						<line id="Line_3" data-name="Line 3" x1="20" y2="20" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1"></line>
					</g>
				</svg>
			</a>
		</div>
	</div>
</template>
<style scoped>
.icon-row {
	display: flex;
	flex-direction: row;
}
.disabled-button {
	pointer-events: none;
	background: transparent;
	color: #4E545D;
	border: 1px solid #4E545D;
}
.custom-value {
	display: block !important;
	width: 300px !important;
	margin-top: 5px;
	padding: 8px 10px;
	background-color: #4E545D;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 300;
	color: #8E9298;
}

@media screen and (min-width: 320px) and (max-width: 991px){
	.custom-value {
		width: 100% !important;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dashboard-main .dashboard-outer .dash-mobile-title {
		padding:unset;
		padding-bottom: 10px;
	}
}
.label-container {
	display: flex;
}
.not-set-label {
	font-size: 0.8rem;
	color: #8f8f8f;
	margin-left: 5px;
}
.active-label {
	font-size: 0.8rem;
	color: #28a745;
	margin-left: 5px;
}
.icon-check-mark {
	fill: #28a745;
	width: 18px;
	height: 16px;
	margin-left: 15px;
	margin-top:10px;
}
.icon-error {
	width: 28px;
	height: 18px;
	fill: #dc3545;
	margin-left: 10px;
	margin-top:10px;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .dashboard-footer .dash-btns:last-child {
	text-align: left;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .player-protection-main .dash-content-outer .dash-main-content .player-protection-outer .content-outer.single-column .content-inner:nth-child(1) {
	padding-right: unset;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .close-btn {
	right: unset;
	left: 50px;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .register-inner-form select {
	background-position: 5%;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .register-inner-form .form-input {
	margin-right: 5px;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer .content-outer .content-inner .list-instructions {
	padding-bottom: 15px;
}
html[dir="rtl"] .not-set-label {
	font-size: 0.8rem;
	color: #8f8f8f;
	margin-right: 5px;
	margin-left: unset;
	margin-top: 2px;
}
html[dir="rtl"] .active-label {
	font-size: 0.8rem;
	color: #28a745;
	margin-right: 5px;
	margin-left: unset;
	margin-top: 2px;
}
@media screen and (min-width: 320px) and (max-width: 767px){
	html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-tab-content .dash-content-main .dash-content-outer {
		overflow-x: hidden;
	}
}
.inline {
	display: flex;
	justify-content: start;
	align-items: center;
}
</style>
