/* eslint-disable */
import { defineStore } from "pinia";
import _ from "lodash";
import { MyBets } from "./MyBets.js";
import * as Combinatorics from "js-combinatorics";

import { applyPatch } from "rfc6902";
import { useSocketStore } from '@/stores/socket';
import { useCustomerStore } from "@/stores/customer.js";
import { useMissionsStore } from "./missions.js";
import { openModal } from '@kolirt/vue-modal';
import MissionCompletion from "@/components/modals/missions/MissionCompletion.vue";
import { useSharedBetslipsStore } from "./shared_betslips.js";

export const BetSlip = defineStore({ id: "BetSlip",
	state: () => ({
		betslip_tax: 0,
		is_betslip_opened: false,
		tab_setted: false,
		same_fixture_error_message: false,
		betSlipModel: "",
		selectedTab: "singles",
		// numKeyPad: [1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0],
		betslip: {
			type: "singles",
			bets: {},
			placed: false,
			error_message: null,
			multiple_freebet_id: null,
			multiple_stake: 0,
			systemX2_stake: 0,
			systemX3_stake: 0,
			systemX4_stake: 0,
			systemX5_stake: 0,
			systemX6_stake: 0,
			systemX7_stake: 0,
			systemX8_stake: 0,
			systemX9_stake: 0,
			systemX10_stake: 0,
			systemX11_stake: 0,
			systemX12_stake: 0,
			systemX13_stake: 0,
			systemX14_stake: 0,
			system_singles_stake: 0,
			system_multiple_stake: 0,
		},
		processing_placement: false,
		betslip_deleted: false
	}),
	getters: {
		isOddInBetslip: (state) => (odd_uuid) => {
			if (state.betslip.bets.hasOwnProperty(odd_uuid)) return true;
			else return false;
		},
		isOddSuspended: () => (odd) => {
			if (!odd) return true;
			else if (odd.hasOwnProperty("status") && odd.status == "suspended") return true;

			else return false;
		},
		isBetslipOpened(state) {
			return state.is_betslip_opened;
		},
		getSelectedTab(state) {
			return state.betslip.type;
		},
		countBetslipBets(state) {
			return Object.keys(state.betslip.bets).length;
		},
		singleBetslipEligibleFreebets: (state) => (uuid) => {
			const customerStore = useCustomerStore();
			let bet_cloned = _.cloneDeep(state.betslip.bets[uuid]);

			let eligible_freebets = [];
			for (const freebet of customerStore.freebets) {
				if (freebet.minimum_selections > 1) continue;
				if (freebet.minimum_odd_price > bet_cloned.price) continue;

				if (freebet.odd_type == "live" && !bet_cloned.odd_info.live) continue;
				if (freebet.odd_type == "pre" && bet_cloned.odd_info.live) continue;

				if (freebet.eligible_sports.length > 0) {
					if (!freebet.eligible_sports.includes(bet_cloned.odd_info.sport_id)) continue;
				}

				if (freebet.eligible_competitions.length > 0) {
					if (!freebet.eligible_competitions.includes(bet_cloned.odd_info.competition_id)) continue;
				}

				// Check market id
				// Check if I have permission for this market
				let market_permission = _.find(freebet.markets, {'market_id': bet_cloned.odd_info.market_external_id });
				if (market_permission) {
					if (market_permission.permission == 'deny') continue;
				} else {
					// Now check global freebet market permission
					let global_permission = _.find(freebet.markets, {'market_id': 0 });
					if (global_permission) {
						if (global_permission.permission == 'deny') continue;
					}
				}

				// Check fixture id
				// Check if I have permission for this fixture
				let fixture_permission = _.find(freebet.fixtures, {'fixture_id': bet_cloned.odd_info.fixture_id });
				if (fixture_permission) {
					if (fixture_permission.permission == 'deny') continue;
				} else {
					// Now check global freebet market permission
					let global_permission = _.find(freebet.fixtures, {'fixture_id': 0 });
					if (global_permission) {
						if (global_permission.permission == 'deny') continue;
					}
				}

				eligible_freebets.push(freebet);
			}

			return eligible_freebets;
		},
		multipleBetslipEligibleFreebets(state) {
			const customerStore = useCustomerStore();

			let eligible_freebets = [];
			for (const freebet of customerStore.freebets) {
				if (freebet.minimum_selections > state.countBetslipBets) continue;

				let elegible_freebet = true;
				let betslip_price = 1;
				for (const bet_uuid in state.betslip.bets) {
					if (Object.hasOwnProperty.call(state.betslip.bets, bet_uuid)) {
						const bet = state.betslip.bets[bet_uuid];
						betslip_price = betslip_price * bet.price;

						if (Number(freebet.minimum_odd_price) > Number(bet.price)) {
							elegible_freebet = false;
							continue;
						}

						if (freebet.odd_type == "live" && !bet.odd_info.live) {
							elegible_freebet = false;
							continue;
						}

						if (freebet.odd_type == "pre" && bet.odd_info.live) {
							elegible_freebet = false;
							continue;
						}

						if (freebet.eligible_sports.length > 0) {
							if (!freebet.eligible_sports.includes(bet.odd_info.sport_id)) {
								elegible_freebet = false;
								continue;
							}
						}

						if (freebet.eligible_competitions.length > 0) {
							if (!freebet.eligible_competitions.includes(bet.odd_info.competition_id)) {
								elegible_freebet = false;
								continue;
							}
						}

						// Check market id
						// Check if I have permission for this market
						let market_permission = _.find(freebet.markets, {'market_id': bet.odd_info.market_external_id });
						if (market_permission) {
							if (market_permission.permission == 'deny') {
								elegible_freebet = false;
								continue;
							}
						} else {
							// Now check global freebet market permission
							let global_permission = _.find(freebet.markets, {'market_id': 0 });
							if (global_permission) {
								if (global_permission.permission == 'deny') {
									elegible_freebet = false;
									continue;
								}
							}
						}

						// Check fixture id
						// Check if I have permission for this fixture
						let fixture_permission = _.find(freebet.fixtures, {'fixture_id': bet.odd_info.fixture_id });
						if (fixture_permission) {
							if (fixture_permission.permission == 'deny') {
								elegible_freebet = false;
								continue;
							}
						} else {
							// Now check global freebet market permission
							let global_permission = _.find(freebet.fixtures, {'fixture_id': 0 });
							if (global_permission) {
								if (global_permission.permission == 'deny') {
									elegible_freebet = false;
									continue;
								}
							}
						}
					}
				}

				// Check betslip price
				if (freebet.minimum_odd_price_betslip > betslip_price) elegible_freebet = false;

				if (elegible_freebet) eligible_freebets.push(freebet);
			}

			return eligible_freebets;
		},
		calculateTotalStakes(state) {
			let single_bet_stakes = 0;
			let system_stakes = 0;

			for (const bet_uuid in state.betslip.bets) {
				if (Object.hasOwnProperty.call(state.betslip.bets, bet_uuid)) {
					const bet = state.betslip.bets[bet_uuid];

					if (!isNaN(bet.stake)) single_bet_stakes += Number(bet.stake);
				}
			}

			if (Object.keys(state.betslip.bets).length > 2) {
				for (let index = 2; index <= Object.keys(state.betslip.bets).length; index++) {
					let system_stake = state.betslip['systemX' + index + '_stake'];
					let combinations = Combinatorics.Combination.of(Object.values(state.betslip.bets), index);

					system_stake = Number(system_stake) * Number(combinations.length);

					if (!isNaN(system_stake)) system_stakes += Number(system_stake);
				}
			}

			let multiple_stake = state.betslip.multiple_stake;
			if (isNaN(multiple_stake)) multiple_stake = '0.00';

			let system_singles_stake = state.betslip.system_singles_stake;
			if (isNaN(system_singles_stake)) system_singles_stake = '0.00';

			let system_multiple_stake = state.betslip.system_multiple_stake;
			if (isNaN(system_multiple_stake)) system_multiple_stake = '0.00';

			let total_stake = Number(single_bet_stakes) + Number(multiple_stake) + Number(system_stakes) + Number(system_singles_stake) + Number(system_multiple_stake);

			return total_stake;
		},
		hasSPOdd(state) {
			let sp_odd_bets = _.filter(state.betslip.bets, function (betslip_bet) {
				return betslip_bet.odd_info.sp == true;
			});

			if (sp_odd_bets.length > 0) return true;

			return false;
		}
	},
	actions: {
		setBetslipTax(data) {
			this.betslip_tax = data;
		},
		toggleBetslip() {
			this.is_betslip_opened = !this.is_betslip_opened;
		},
		setSelectedTab(tab) {
			this.betslip.type = tab;
		},
		async toggleToBetslip(payload, shared_betslip_id) {

			const socketStore = useSocketStore();

			if (this.betslip.placed == true) {
				this.resetBetslip();
			}

			if (this.betslip.bets.hasOwnProperty(payload.odd_uuid)) {
				this.removeFromBetslip(payload);
				if (Object.keys(this.betslip.bets).length == 0) {
					this.betSlipModel = "";
				}

				return;
			}

			if (Object.keys(this.betslip.bets).length >= 50) {
				if (this.betSlipModel == "") this.betSlipModel = 'betslip';
				return;
			}

			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/odds/info/?fixture_id=' + payload.fixture_id + '&odd_uuid=' + payload.odd_uuid, { withCredentials: false }).then((response) => {
				let bet = {
					odd_info: response.data,
					price: response.data.price,
					has_error: false,
					stake: 0,
					freebet_id: null
				}

				this.betslip.bets[bet.odd_info.uuid] = bet;
				socketStore.getSocket.emit('join-fixtureOddInfo', { fixture_id: bet.odd_info.fixture_id, odd_uuid: bet.odd_info.uuid })

				// open betslip if is first bet
				if (this.countBetslipBets == 1) this.betSlipModel = "betslip";
				return Promise.resolve(response.data);
			}).catch((error) => {
				if (shared_betslip_id) {
					this.resetBetslip();
					return Promise.reject(error.response.data);
				} else {
					alert('Cannot Place Bet');
					console.log(error);
					return Promise.reject(error.response.data);
				}
			});
		},
		resetBetslip() {
			this.leaveOddInfos();

			// reset betslip state to default
			this.betslip.type = "singles";
			this.betslip.bets = {};
			this.betslip.placed = false;
			this.betslip.error_message = null;
			this.betslip.multiple_freebet_id = null;
			this.betslip.multiple_stake = 0;
			this.betslip.systemX2_stake = 0;
			this.betslip.systemX3_stake = 0;
			this.betslip.systemX4_stake = 0;
			this.betslip.systemX5_stake = 0;
			this.betslip.systemX6_stake = 0;
			this.betslip.systemX7_stake = 0;
			this.betslip.systemX8_stake = 0;
			this.betslip.systemX9_stake = 0;
			this.betslip.systemX10_stake = 0;
			this.betslip.systemX11_stake = 0;
			this.betslip.systemX12_stake = 0;
			this.betslip.systemX13_stake = 0;
			this.betslip.systemX14_stake = 0;
			this.betslip.system_singles_stake = 0;
			this.betslip.system_multiple_stake = 0;
		},
		addErrorMessageToBetslip() {
			let error_message = "The odds and/or availability of your selections have changed.";
			this.betslip.error_message = error_message;
		},
		removeFromBetslip(payload) {
			if (!this.betslip.bets.hasOwnProperty(payload.odd_uuid)) return;

			delete this.betslip.bets[payload.odd_uuid];
			this.leaveFixtureOddInfo({ fixture_id: payload.fixture_id, odd_uuid: payload.odd_uuid });

			if (this.countBetslipBets == 1) this.betslip.type = "singles";
			if (this.countBetslipBets >= 2) this.betslip.type = "multiple";

			if (this.countBetslipBets == 0) {
				this.is_betslip_opened = false;
				this.betSlipModel = '';
				this.resetBetslip();
			}
		},
		removeBetslipErrors() {
			for (const odd_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, odd_uuid)) {
					const bet = this.betslip.bets[odd_uuid];

					// Remove if suspended
					if (bet.odd_info.status == "suspended") {
						this.removeFromBetslip({ odd_uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id});
						continue;
					}

					// Fix price
					if (this.betslip.bets[odd_uuid].price != this.betslip.bets[odd_uuid].odd_info.price) {
						this.betslip.bets[odd_uuid].price = this.betslip.bets[odd_uuid].odd_info.price;
					}

					// Remove has error
					this.betslip.bets[odd_uuid].has_error = false;
				}
			}

			// Remove error message from betslip
			this.betslip.error_message = null;
		},
		toggleFreebetOnSingle(payload) {
			// Find if bet already has this freebet
			if (this.betslip.bets[payload.odd_uuid].freebet_id == payload.freebet.id) {
				this.betslip.bets[payload.odd_uuid].freebet_id = null;
				this.betslip.bets[payload.odd_uuid].stake = 0;
			} else {
				// Remove freebet from other bets
				for (const bet_uuid in this.betslip.bets) {
					if (this.betslip.bets[bet_uuid].freebet_id == payload.freebet.id) {
						this.betslip.bets[bet_uuid].freebet_id = null;
						this.betslip.bets[bet_uuid].stake = 0;
					}
				}

				// Add freebet to selected bet
				this.betslip.bets[payload.odd_uuid].freebet_id = payload.freebet.id;
				this.betslip.bets[payload.odd_uuid].stake = payload.freebet.amount;
			}
		},
		toggleFreebetOnMultiple(freebet) {
			if (this.betslip.multiple_freebet_id == freebet.id) {
				this.betslip.multiple_freebet_id = null;
				this.betslip.multiple_stake = 0;

				return;
			}

			this.betslip.multiple_freebet_id = freebet.id;
			this.betslip.multiple_stake = freebet.amount;
		},
		validateSameFixtures() {
			this.same_fixture_error_message = false;
			for (const odd_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, odd_uuid)) {
					const bet = this.betslip.bets[odd_uuid];

					let same_fixtures = _.filter(this.betslip.bets, function ( betslip_bet) {
						return betslip_bet.odd_info.fixture_id == bet.odd_info.fixture_id && betslip_bet.odd_info.uuid != bet.odd_info.uuid;
					});

					if (same_fixtures.length > 0 && this.betslip.type != "singles") {
						for (const same_fixture_bet of same_fixtures) {
							this.betslip.bets[same_fixture_bet.odd_info.uuid].has_error = true;
							this.same_fixture_error_message = true;
						}
					} else {
						this.betslip.bets[odd_uuid].has_error = false;
					}
				}
			}
		},
		socketJoinBets() {
			const socketStore = useSocketStore();

			for (const odd_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, odd_uuid)) {
					const bet = this.betslip.bets[odd_uuid];
					socketStore.getSocket.emit('join-fixtureOddInfo', { fixture_id: bet.odd_info.fixture_id, odd_uuid: bet.odd_info.uuid });

					global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/odds/info/?fixture_id=' + bet.odd_info.fixture_id + '&odd_uuid=' + bet.odd_info.uuid, { withCredentials: false }).then((response) => {
						//
					}).catch((error) => {
						if (error.response && error.response.status == 404) {
							this.addErrorMessageToBetslip();
							this.betslip.bets[odd_uuid].odd_info.status = 'suspended';
						} else {
							console.log(error);
						}
					});
				}
			}
		},
		async placeBetslip() {
			if (this.calculateTotalStakes == 0) {
				return;
			}

			let betslip = _.cloneDeep(this.betslip);

			if (betslip.system_singles_stake > 0) {
				betslip = this.addSingleStakeToBets(betslip);
				betslip.type = null;
			}

			if (betslip.system_multiple_stake > 0) {
				betslip.multiple_stake = betslip.system_multiple_stake;
				betslip.type = null;
			}

			if (this.calculateTotalStakes == 0) return;
			const customerStore = useCustomerStore();
			const myBetsStore = MyBets();

			this.processing_placement = true;
			global.axios.post(process.env.VUE_APP_API_URL + '/betslip/place', JSON.stringify(betslip), { headers: {'Content-Type' : 'application/json'} }).then((response) => {
				let type = _.cloneDeep(this.betslip.type);
				this.betslip = response.data;
				this.betslip.type = type;
				this.leaveOddInfos();
				customerStore.fetchCustomer();
				myBetsStore.fetchMyBets();

				if (response.data.completed_mission != null) {
					const missionsStore = useMissionsStore();
					missionsStore.setSelectedMission(response.data.completed_mission);
					openModal(MissionCompletion).then((data) => {}).catch(() =>{});
				}
			}).catch((error) => {
				if (error.response.status == 406 || error.response.status == 401) {
					if (error.response.data) {
						let type = _.cloneDeep(this.betslip.type);
						this.betslip = error.response.data;
						this.betslip.type = type;
						this.addSingleBetsStakeToSystemSingle();
						this.addSingleBetsStakeToSystemMultiple();
					}
				} else {
					console.log(error);
				}
			}).finally(() => {
				this.processing_placement = false;
			});
		},
		addSingleStakeToBets(betslip) {
			for (const key in betslip.bets) {
				if (Object.hasOwnProperty.call(betslip.bets, key)) {
					betslip.bets[key].stake = betslip.system_singles_stake;
				}
			}

			return betslip;
		},
		addSingleBetsStakeToSystemSingle() {
			if (this.betslip.type != 'system') return;
			if (this.betslip.system_singles_stake == 0) return;

			let min_stake_of_singles = 0;
			for (const key in this.betslip.bets) {

				if (Object.hasOwnProperty.call(this.betslip.bets, key)) {
					min_stake_of_singles += Number(this.betslip.bets[key].stake);
				}
			}

			this.betslip.system_singles_stake = min_stake_of_singles;
		},
		addSingleBetsStakeToSystemMultiple() {
			if (this.betslip.type != 'system') return;
			if (this.betslip.system_multiple_stake == 0) return;

			this.betslip.system_multiple_stake = this.betslip.multiple_stake
		},

		leaveOddInfos() {
			// leave odd info chanels
			for (const bet_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, bet_uuid)) {
					const bet = this.betslip.bets[bet_uuid];

					this.leaveFixtureOddInfo({fixture_id: bet.odd_info.fixture_id, odd_uuid: bet.odd_info.uuid });
				}
			}
		},
		leaveFixtureOddInfo(payload) {
			const socketStore = useSocketStore();
			const myBetsStore = MyBets();
			let found = false;

			let betslips = _.filter(myBetsStore.my_bets, {
				betslip_result: "unsettled",
			});

			for (const element of betslips) {
				const betslip = element;
				let bet = _.find(betslip.bets, { uuid: payload.odd_uuid });
				if (bet) {
					found = true;
					break;
				}
			}

			if (!found) socketStore.getSocket.emit('leave-fixtureOddInfo', { fixture_id: payload.fixture_id, odd_uuid: payload.odd_uuid })
		},
		keepBetslip() {
			this.socketJoinBets();

			this.betslip.placed = false;
			this.betslip.error_message = null;
			this.betslip.multiple_freebet_id = null;
			this.betslip.multiple_stake = 0;
			this.betslip.systemX2_stake = 0;
			this.betslip.systemX3_stake = 0;
			this.betslip.systemX4_stake = 0;
			this.betslip.systemX5_stake = 0;
			this.betslip.systemX6_stake = 0;
			this.betslip.systemX7_stake = 0;
			this.betslip.systemX8_stake = 0;
			this.betslip.systemX9_stake = 0;
			this.betslip.systemX10_stake = 0;
			this.betslip.systemX11_stake = 0;
			this.betslip.systemX12_stake = 0;
			this.betslip.systemX13_stake = 0;
			this.betslip.systemX14_stake = 0;

			for (const uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, uuid)) {
					this.betslip.bets[uuid].stake = 0;
					this.betslip.bets[uuid].freebet_id = null;
				}
			}
		},
		addFixtureOddInfo(data) {
			if (!this.betslip.bets.hasOwnProperty(data.uuid)) return;

			this.betslip.bets[data.uuid].odd_info = data;
			this.betslip.bets[data.uuid].price = data.price;
		},
		updateFixtureOddInfo(data) {
			const customerStore = useCustomerStore();


			let bet_cloned = _.cloneDeep(this.betslip.bets[data.uuid]);
			if (!bet_cloned) return;

			// Update bet
			applyPatch(bet_cloned.odd_info, data.patch);

			// Check price change for always_ask && accept_higher_odds
			if (
			(bet_cloned.price != bet_cloned.odd_info.price &&
				customerStore.betslipChanges == "always_ask") ||
			(bet_cloned.price > bet_cloned.odd_info.price &&
				customerStore.betslipChanges == "accept_higher_odds")
			) {
				this.addErrorMessageToBetslip();
			} else {
				bet_cloned.price = bet_cloned.odd_info.price;
			}

			if (bet_cloned.odd_info.status == "suspended") {
				this.addErrorMessageToBetslip();
			}

			// Add updated bet to betslip
			this.betslip.bets[data.uuid] = bet_cloned;
		},
		deleteFixtureOddInfo(data) {
			if (!this.betslip.bets.hasOwnProperty(data)) return;

			this.addErrorMessageToBetslip();
			this.betslip.bets[data].odd_info.status = "suspended";
		},
		removeFreebetSingle(uuid) {
			this.betslip.bets[uuid].freebet_id = null;
			return;
		},
		removeSingleFreebet(uuid) {
			this.betslip.bets[uuid].freebet_id = null;
			this.betslip.bets[uuid].stake = 0;
			return;
		},
		removeFreebet() {
			this.betslip.multiple_freebet_id = null;
			this.betslip.multiple_stake = 0;
			return;
		},
		validateInput(current_value, type, value) {
			current_value = current_value + "";

			switch (type) {
				case "add":
					if (current_value) current_value = parseFloat(current_value) + value;
					else current_value = value;
				break;
				case "append":
					if (value == "." && current_value.indexOf(".") == -1)
						current_value = current_value + value;
					else if (value != ".") current_value = current_value + "" + value;
				break;
				case "delete":
					current_value = current_value.slice(0, -1);
				break;
			}

			return current_value;
		},
		calculateMaxBet(payload) {
			return axios.post(process.env.VUE_APP_API_URL + '/betslip/max-bet', payload, { headers: {'Content-Type' : 'application/json'} }).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response.data);
			});
		}
	},

  persist: true,
});
