import { defineStore } from 'pinia';
import 'moment/dist/locale/el';
import 'moment/dist/locale/de';
import 'moment/dist/locale/fa';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/sr';
import 'moment/dist/locale/tr';
import 'moment/dist/locale/es';
import 'moment/dist/locale/pt';
import { enUS, el, de, faIR, fr, sr, tr, es, pt } from 'date-fns/locale';

import { useLanguagesStore } from './languages';
import { useTimezonesStore } from './timezones';
import { useCustomerStore } from './customer';
import moment from 'moment';

export const useDatesStore = defineStore('dates', {
	state: () => ({
		//
	}),
	getters: {
		translateAndOffsetDatetime: () => (datetime, force = false) => {
			const languagesStore = useLanguagesStore();
			const timezonesStore = useTimezonesStore();
			const customerStore = useCustomerStore();

			let locale = 'en';
			locale = languagesStore.selectedLanguage.locale;
			if (locale == 'gr') locale = 'el';
			moment.locale(locale);

			let utc_datetime = moment(datetime).utcOffset(0, force);
			let utc_offset = timezonesStore.selectedTimezone.utc_offset;

			if (!customerStore.isLoggedIn) {
				utc_offset = moment().format('Z');
			}

			let offset_datetime = utc_datetime.utcOffset(utc_offset);

			return offset_datetime;
		},
		preFixtureDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('DD/MM HH:mm' );
		},
		competitionFixtureDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('dddd DD/MM/YY');
		},
		preFixtureBookDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('DD/MM');
		},
		preFixtureBookTime: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('HH:mm');
		},
		historyDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('DD/MM/YYYY' );
		},
		historyTime: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('HH:mm:ss' );
		},
		accountBonusDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('DD/MM/YY HH:mm');
		},
		defaultDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('DD/MM/YY HH:mm');
		},
		documentDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('YYYY-MM-DD HH:mm:ss');
		},
		fixtureSearchDate: (state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('dddd DD/MM/YY HH:mm');
		},
		messageDate: (state) => (datetime, force = false) => {
			if (state.translateAndOffsetDatetime(datetime, force).isSame(moment(), 'day')) {
				return 'Today, ' + state.translateAndOffsetDatetime(datetime, force).format('HH:mm');
			}else {
				return state.translateAndOffsetDatetime(datetime, force).format('dddd D MMMM, YYYY');
			}
		},
		messageDocumentDate:(state) => (datetime, force = false) => {
			return state.translateAndOffsetDatetime(datetime, force).format('DD MMM, YYYY');
		},
		messagesDate: (state) => (datetime, force = false) => {
			if (state.translateAndOffsetDatetime(datetime, force).isSame(moment(), 'day')) {
				return state.translateAndOffsetDatetime(datetime, force).format('HH:mm A');
			}else {
				return state.translateAndOffsetDatetime(datetime, force).format('DD/MM HH:mm A' );
			}
		},
		datepickerLocale() {
			const languagesStore = useLanguagesStore();

			let locale = enUS;

			if (languagesStore.selectedLanguage.locale == 'gr') locale = el;
			else if (languagesStore.selectedLanguage.locale == 'de') locale = de;
			else if (languagesStore.selectedLanguage.locale == 'fa') locale = faIR;
			else if (languagesStore.selectedLanguage.locale == 'fr') locale = fr;
			else if (languagesStore.selectedLanguage.locale == 'sr') locale = sr;
			else if (languagesStore.selectedLanguage.locale == 'tr') locale = tr;
			else if (languagesStore.selectedLanguage.locale == 'es') locale = es;
			else if (languagesStore.selectedLanguage.locale == 'pt') locale = pt;

			return locale;
		},
	},
	actions: {
		//
	},
})