<!-- eslint-disable -->
<script setup>
import { openModal } from '@kolirt/vue-modal';
import Login from '../modals/Login.vue';
import Register from '../modals/Register.vue';
import SportsbookSearch from '../modals/SportsbookSearch.vue';
import SettingDropdown from '../Common/settingDropdown.vue';
import { useNavbarStore } from '@/stores/navbar_items';
import { BetSlip } from '@/stores/BetSlip';
import commonSvgs from '@/assets/svgs/commonSvgs.json';

const svgs_data = commonSvgs;
const navbarStore = useNavbarStore();
const betslipStore = BetSlip();
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() =>{});
};

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';

const showSportsbookSearchModal = (event) => {
	event.stopPropagation();
	openModal(SportsbookSearch).then((data) => {
	}).catch(() =>{});
		betslipStore.is_betslip_opened = false;
		betslipStore.betSlipModel = '';
		navbarStore.user_dropdown_opened = false;
		navbarStore.settings_dropdown = false;
		navbarStore.mobile_menu = false;
};

const showSettings = () => {
	navbarStore.settings_dropdown = !navbarStore.settings_dropdown;
	navbarStore.user_dropdown_opened = false;
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
	navbarStore.mobile_menu = false;
};
</script>
<template>
	<div v-if= is_country_ghana class="row-container">
		<div class="header-icons for-ghana">
		<a @click="showSportsbookSearchModal($event)">
			<svg xmlns="http://www.w3.org/2000/svg" width="25.207" height="25.207" viewBox="0 0 25.207 25.207">
				<g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-1.75 -1.75)">
					<path id="Path_3" data-name="Path 3" d="M24.75,13.5A11.25,11.25,0,1,1,13.5,2.25,11.25,11.25,0,0,1,24.75,13.5Z" transform="translate(0)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
					<path id="Path_4" data-name="Path 4" d="M17.23,17.225l-4.743-4.737" transform="translate(9.02 9.025)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
				</g>
			</svg>
		</a>
		<div class="setting-main">
			<object  v-html="svgs_data.setting_icon" @click="showSettings()"></object>
			<div v-if="navbarStore.settings_dropdown" class="setting-dropdown-main is-ghana active">
				<setting-dropdown v-if="navbarStore.settings_dropdown"></setting-dropdown>
			</div>
		</div>
		</div>
		<!-- <div class="header-btn"> -->
			<a @click="showLoginModal()" class="btn-small">{{ $t('general.login') }}</a>
			<a @click="showRegisterModal()" class="btn-small btn-secondary">{{ $t('general.register') }}</a>
		<!-- </div> -->
	</div>
	<div v-else class="header-icons">
		<a @click="showRegisterModal()">
			<svg xmlns="http://www.w3.org/2000/svg" width="25.207" height="25.207" viewBox="0 0 22.999 19">
				<g id="Group_1049" data-name="Group 1049" transform="translate(-299.501 -15.5)">
				<g id="Group_1047" data-name="Group 1047" transform="translate(-23.192)">
					<g id="g10342" transform="translate(327.312 16)">
					<path id="path10344" d="M0-165.785a4.881,4.881,0,0,1,4.881-4.881,4.881,4.881,0,0,1,4.881,4.881A4.881,4.881,0,0,1,4.881-160.9,4.881,4.881,0,0,1,0-165.785Z" transform="translate(0 170.666)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
					</g>
					<g id="g10346" transform="translate(323.193 25.763)">
					<path id="path10348" d="M-494.892-37.561a8.411,8.411,0,0,1,2.139,1.336,3.8,3.8,0,0,1,1.289,2.859v1.411a1.525,1.525,0,0,1-1.525,1.525h-14.948a1.525,1.525,0,0,1-1.525-1.525v-1.411a3.8,3.8,0,0,1,1.289-2.859,11.568,11.568,0,0,1,7.71-2.442" transform="translate(509.463 38.667)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
					</g>
				</g>
				<g id="Group_1048" data-name="Group 1048">
					<line id="Line_72" data-name="Line 72" y1="5" transform="translate(319.5 18.5)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-width="1"/>
					<line id="Line_73" data-name="Line 73" y1="5" transform="translate(322 21) rotate(90)" fill="none" stroke="#f5bd42" stroke-linecap="round" stroke-width="1"/>
				</g>
				</g>
			</svg>
		</a>
		<a @click="showLoginModal()">
			<svg xmlns="http://www.w3.org/2000/svg" width="25.207" height="25.207" viewBox="0 0 18.999 19">
				<g id="Group_1046" data-name="Group 1046" transform="translate(-322.693 -15.5)">
				<g id="g10342" transform="translate(327.312 16)">
					<path id="path10344" d="M0-165.785a4.881,4.881,0,0,1,4.881-4.881,4.881,4.881,0,0,1,4.881,4.881A4.881,4.881,0,0,1,4.881-160.9,4.881,4.881,0,0,1,0-165.785Z" transform="translate(0 170.666)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
				</g>
				<g id="g10346" transform="translate(323.193 25.763)">
					<path id="path10348" d="M-494.892-37.561a8.411,8.411,0,0,1,2.139,1.336,3.8,3.8,0,0,1,1.289,2.859v1.411a1.525,1.525,0,0,1-1.525,1.525h-14.948a1.525,1.525,0,0,1-1.525-1.525v-1.411a3.8,3.8,0,0,1,1.289-2.859,11.568,11.568,0,0,1,7.71-2.442" transform="translate(509.463 38.667)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
				</g>
				</g>
			</svg>
		</a>


		<div class="setting-main" id="settings-dropdown">
			<object  v-html="svgs_data.setting_icon" @click="showSettings()"></object>
			<div v-if="navbarStore.settings_dropdown" class="setting-dropdown-main active">
				<setting-dropdown v-if="navbarStore.settings_dropdown"></setting-dropdown>
			</div>
		</div>
		<a @click="showSportsbookSearchModal($event)">
			<svg xmlns="http://www.w3.org/2000/svg" width="25.207" height="25.207" viewBox="0 0 25.207 25.207">
				<g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-1.75 -1.75)">
					<path id="Path_3" data-name="Path 3" d="M24.75,13.5A11.25,11.25,0,1,1,13.5,2.25,11.25,11.25,0,0,1,24.75,13.5Z" transform="translate(0)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
					<path id="Path_4" data-name="Path 4" d="M17.23,17.225l-4.743-4.737" transform="translate(9.02 9.025)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
				</g>
			</svg>
		</a>
	</div>

</template>
<style scoped>
.row-container {
	display: flex;
	align-items: center;
}

.for-ghana {
	margin-right: 10px;
}

.header-btn {
	margin-left: auto;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.right-content .header-icons .setting-main .is-ghana {
		padding: 15px;
		right: -133px;
	}
	.btn-small {
		font-size: 11px;
		font-weight: 500;
		width: 50%;
	}
	a.btn-small.btn-secondary {
		margin-left: 10px;
	}
	html[dir="rtl"] a.btn-small.btn-secondary {
		margin-left: unset;
		margin-right: 10px;
	}
	.right-content .for-ghana a:first-child svg {
		width: 17px;
		top: unset;
	}

	html[dir="rtl"] .right-content .header-icons .setting-main .is-ghana {
		right: unset;
		left: -128px;
	}
}

html[dir="rtl"] .for-ghana {
	margin-right: unset;
	margin-left: 10px;
}
</style>