<!-- eslint-disable -->

<script setup>
import { useMenuStore } from '@/stores/menus';
import { ref, onMounted, watch } from "vue";
import { useGeneralStore } from '@/stores/general';
import { version } from '../../../package.json';
import Social from './footer_sections/Social.vue';
import Payments from './footer_sections/Payments.vue';
import PaymentMobile from './footer_sections/PaymentMobile.vue';
import Regulation from './footer_sections/Regulation.vue';
import Contact from './footer_sections/Contact.vue';
import Description from './footer_sections/Description.vue';
import DescriptionMobile from './footer_sections/DescriptionMobile.vue';
import RegulationMobile from './footer_sections/RegulationMobile.vue';

const menuStore = useMenuStore();
const generalStore = useGeneralStore();

const getUrl = (menu_child) => {
	if (!menu_child.click_url && !menu_child.static_page_url) return false
	if (menu_child.click_url == '#/promotions/all') return menu_child.click_url.replace('#', '').replace('/all', '');
	if (menu_child.click_url) return menu_child.click_url.replace('#', '');
	else if (menu_child.static_page_url) return menu_child.static_page_url;
}
</script>
<template>
	<footer>
		<div class="container-lg">
			<div class="footer-outer">
				<!-- footer column -->
				<template v-if="menuStore.getFooterMenus">
					<div class="footer-inner" v-for="menu in menuStore.getFooterMenus" :key="menu">
						<h6>{{ menu.name }}</h6>
						<div class="footer-menu ">
							<ul>
								<template v-for="menu_child in menu.children" :key="menu_child">
									<li v-if="menu_child.click_url == '/live-chat'" ><a href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});">{{ menu_child.name }}</a></li>
									<li v-else><a v-if="getUrl(menu_child)" :href="getUrl(menu_child)">{{ menu_child.name }}</a></li>
								</template>
							</ul>
						</div>
					</div>
				</template>
				<!-- end -->

			</div>

			<div class="footer-desktop">
				<div class="footer-outer dynamic">
					<Social> </Social>
					<Regulation> </Regulation>
				</div>
				<div class="footer-outer descr-contact">
					<div class="desc-section">
						<Description></Description>
						<div class="copyright-text">
							<p>© {{ generalStore.getGeneral.site_name }} 2023 Preview Build {{ version }} </p>
						</div>
						<img class="logo" style="margin-top: 20px; height: 37px !important;" :src="generalStore.getGeneral.footer_logo" />
					</div>

					<div class="contact-section">
						<Contact></Contact>
					</div>
				</div>
				<div class="payments-section">
					<Payments></Payments>
				</div>
			</div>

			<div class="footer-mobile">
				<div class="footer-outer dynamic">
					<div class="contact-section">
						<Contact></Contact>
					</div>
				</div>
				<div class="footer-outer dynamic">
					<div class="payments-section">
						<PaymentMobile></PaymentMobile>
					</div>
				</div>
				<div class="footer-outer descr-contact">
					<div class="desc-section">
						<DescriptionMobile></DescriptionMobile>
						<div class="copyright-text">
							<p>© {{ generalStore.getGeneral.site_name }} 2023 Preview Build {{ version }} </p>
						</div>
						<RegulationMobile></RegulationMobile>
					</div>
				</div>
			</div>

		</div>
	</footer>
</template>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 800px) {
	.footer-desktop {
		display:none;
	}
	.social-icon img{
		max-width: 60px;
	}
	.footer-outer.dynamic {
		justify-content:center;
	}
	.footer-inner.contact-container {
		width:100%;
	}
	h6.social-title {
		font-size:14px;
		padding-bottom:5px;
	}
}
@media screen and (min-width: 801px) and (max-width: 3000px) {
	.footer-mobile {
		display:none;
	}
}
@media screen and (min-width: 650px) and (max-width:800px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr; 
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr 1fr 1fr!important;
		gap:35px!important;
	}
	.desc-section {
		width:100%!important;
		padding-right: 70px;
		padding-left: 70px;
	}
}
@media screen and (min-width: 450px) and (max-width:649px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr; 
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr 1fr!important;
		gap:35px!important;
	}
	
}
@media screen and (min-width: 580px) and (max-width:649px) {
	.desc-section {
		width:100%!important;
		padding-right: 70px;
		padding-left: 70px;
	}
}
@media screen and (min-width: 542px) and (max-width:579px) {
	.desc-section {
		width:100%!important;
		padding-right: 60px;
		padding-left: 60px;
	}
}
@media screen and (min-width: 449px) and (max-width:541px) {
	.desc-section {
		width:100%!important;
		padding-right: 40px;
		padding-left: 40px;
	}
}
@media screen and (min-width: 347px) and (max-width:449px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr; 
		column-gap:30px;
		row-gap:10px;
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr!important;
		column-gap:35px!important;
	}
	.desc-section {
		width:100%!important;
		padding-right: 30px;
		padding-left: 30px;
	}
	a.custom-font{
		margin-right:30px;
	}
	h6.social-title.payment {
		padding-left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width:346px) {
	.payment-grid {
		display: grid;
		grid-template-columns: 1fr 1fr; 
		column-gap:15px;
		row-gap:10px;
	}
	.contact-grid{
		display: grid!important;
		grid-template-columns: 1fr 1fr!important;
		column-gap:20px!important;
	}
	.desc-section {
		width:100%!important;
		padding-right: 20px;
		padding-left: 20px;
	}
	a.custom-font{
		margin-right:30px;
	}
	h6.social-title.payment {
		padding-left: 10px;
	}
}
.contact-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* Four equal columns */
	gap: 10px; /* Adjust the gap between columns as needed */
	justify-items:center;
}
@media screen and (min-width: 1053px) and (max-width: 1359px) {
	.contact-grid {
		grid-template-columns: 1fr 1fr 1fr
	}
}
@media screen and (min-width: 801px) and (max-width: 1052px) { 
	.contact-grid {
		grid-template-columns: 1fr 1fr
	}
	a.custom-font{
		margin-right:30px;
	}
}
.footer-inner.contact-container {
	margin-top:10px;
}

html[dir="rtl"] .footer-outer {
	padding-right: 40px;
}
p.reach_us_by_phone_call{
	color: #63A246;
	font-weight: bold;
}
p.ussd_code{
	color: #63A246;
	font-weight: bold;
	border: 1px solid;
	border-radius: 5px;
	padding: 5px 10px;
	width: 100px;
}

a.custom-font {
	display: inline-block;
	padding: 10px 20px;
	background-color: #FFBB02;
	color: #202833;
	text-decoration: none;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size:13px;
}
a.free-call {
	display: inline-block;
	padding: 10px 8px;
	background-color: transparent;
	text-decoration: none;
	border: 1px solid;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	margin-top: 22px;
	font-size:13px;
}
@media screen and (min-width: 1000px) and (max-width: 3000px) {
	a.free-call {
		margin-top: 22px;
	}
}
.social-icon img{
	max-width: 50px;
	padding:10px;
}
h6.social-title {
	color: white !important;
}
.browser-time {
	background-color: gray;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	/* font-weight: bold; */
	display: inline-block;
	height:100%
}

.social-grid {
	display: flex;
	align-items: center;
}

.desc-section {
	display: flex;
	flex-direction: column;
	width: 50%
}
.copyright-text p {
	font-size: 12px;
	color: #878a90;
}
p.desc {
	font-size: 12px;
	color: #878A90;
}
.logo {
	margin-right:85%
}
.footer-outer.dynamic {
	padding-bottom: 20px;
	padding-top: 20px;
}
.footer-outer.descr-contact {
	padding-bottom: 20px;
	padding-top: 20px;
	justify-content:unset;
}
.payments-icon img{
	max-width: 150px;
	padding:10px;
}

.payments-section {
	padding-top: 20px;
	display: flex;
	justify-content: center;
}
</style>
