import { defineStore } from 'pinia';

export const useMarketsStore = defineStore('markets', {
	state: () => ({
		overview_markets: null
	}),
	getters: {
		sortedMarketGroups: () => (sport_id) => {
			let groups_by_sport = {
				1: ['Main', 'Goals', 'Handicap', 'Half', 'Asian Lines', 'Combo', 'Players', 'Corners', 'Cards', 'Penalties', 'Shots on Target', 'Fouls', 'Offsides', 'Throw-ins', 'Goal Kicks', 'Shots', 'Tackles', 'Saves', 'Substitutions', 'Statistics', 'Extra Time - Penalties', 'Minutes', 'Specials'], // Soccer
				2: ['Main', 'Sets', 'Games', 'Points', 'Handicap', 'Tie Breaks', 'Aces', 'Double Faults', 'Breaks', 'Break Points', 'Specials'], // Tennnis
				3: ['Main', 'Team', 'Quarter', 'Quarter Specials', 'Half', 'Half Specials', 'Rebounds', 'Assists', 'Three-Point Shots', 'Two-Point Shots', 'Free Throws', 'Steals', 'Blocks', 'Turnovers', 'Fouls', 'Statistics', 'Specials'], // Basketball
				4: ['Main', 'Goals', 'Handicap', 'Periods', 'Overtime - Penalty Shootout', 'Team Specials', 'Time Penalty', 'Face-offs', 'Shots on Goal', 'Powerplay', 'Statistics', 'Players', 'Combo', 'Minutes', 'VAR', 'Specials'], // Ice hockey
				5: ['Main', 'Goals', 'Handicap','Half', 'Specials'],
				6: ['Main', 'Runs', 'Handicap', 'Players', 'Hits', 'Home Runs', 'Inning', 'Multiple Innings', 'Specials'], // Baseball
				7: ['Main', 'Goals', 'Quarter', 'Half', 'Corners', 'Combo', 'Minutes', 'Specials'], // Water polo
				8: ['Main', 'Sets', 'Handicap', 'Teams', 'Aces', 'Blocks', 'Serve Faults', 'Statistics', 'Specials', 'Mini Set'], // Volley ball
				9: ['Main', 'Games', 'Handicap', 'Players', 'Specials'], // Badminton
				10: ['Main', 'Game', 'Handicap', 'Specials'], // Table Tennis
				11: ['Main', 'Goals', 'Handicap', 'Half', 'Corners', 'Cards', 'Combo', 'Minutes', 'Specials'], // Futsal
				12: ['Main', 'Round', 'Specials'], // Boxing
				13: [], // American football,
				14: [], // Horse racing football,
				15: ['Main', 'Frame', 'Break', 'Centuries', 'Multiple Frames', 'Specials'], // Snooker
				16: ['Main', 'Map', 'Rounds', 'Kills', 'Specials'], // Counter Strike
				17: [], // MMA
				18: [], // Politics
				19: [], // Archery
				20: [], // Greyhounds,
				21: ['Main', 'Map', 'Kills', 'Roshans', 'Towers', 'Barracks', 'Combo', 'Specials'], // Dota 2
				22: ['Main', 'Over', 'Runs', 'Wickets', 'Boundaries', 'Statistics', 'Players', 'Innings', 'Specials'], // Cricket
				23: ['Main', 'Maps'], // Valorant
				24: [], // Rubgy union
				25: [], // Floorball
				26: [], // Curling
				27: ['Main', 'Map', 'Kills', 'Dragons', 'Barons', 'Towers', 'Inhibitors', 'Specials'], // League of Legends
				28: [], // Chess,
				29: ['Main', 'Map'],
				30: [], // Hockey,
				31: [], // Bowls,
				32: [], // Pistol Shooting,
				33: ['Main', 'Sets'], // Beach Volleyball,
				35: [], // Formula 1
				36: [], // Darts
				37: [], // Golf
				38: [], // Archery Shooting
				39: [], // Aussie Rules
				40: [], // Rugby Leauge
				41: [], // Beach Soccer
				42: [], // Netball
				43: [], // Squash
				44: [], // Cycling
				45: [], // Gaelic Football
				46: [], // Shooting
			};

			return groups_by_sport[sport_id];
		},

		sportOverviewMarkets: (state) => (sport_id) => {
			if (sport_id == null) return;
			return state.overview_markets[sport_id];
		},
		overviewMarketNeedsIndicator: () => (market) => {
			if (!market) return false;

			switch (market.market_type) {
				case 'totals':
				case 'handicap':
				case 'set':
					return true;
				default:
					return false;
			}
		},
	},
	actions: {
		fetchOverviewMarkets() {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/sports/overview_markets', { withCredentials: false }).then((response) => {
				this.overview_markets = response.data;
			}).catch((error) => {
				return Promise.reject(error);
			})
		}
	},
})