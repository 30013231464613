<script setup>
/* eslint-disable */
import { openModal } from '@kolirt/vue-modal';
import { useRouter } from 'vue-router';
import { computed, watch, onMounted, ref } from 'vue';
import { BetSlip } from '@/stores/BetSlip';
import { useLanguagesStore } from '@/stores/languages';
import { useCurrenciesStore } from '@/stores/currencies';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useCustomerStore } from '@/stores/customer';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useSharedBetslipsStore } from '@/stores/shared_betslips';
import { useI18n } from 'vue-i18n';

import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';

import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { useOddsStore } from '@/stores/odds';

const svg_data = ref(commonSvgs);

const oddsStore = useOddsStore();
const router = useRouter();
const betslipStore = BetSlip();
const languagesStore = useLanguagesStore();
const currenciesStore = useCurrenciesStore();
const preSportsStore = usePreSportsStore();
const customerStore = useCustomerStore();
const liveSportsStore = useLiveSportsStore();
const sharedBetslipStore = useSharedBetslipsStore();
const { t } = useI18n();
const share_url = ref(null);
const loading = ref(false);
const share_id = ref(null);
const share_url_text = ref(t('general.copy_url'));
const share_id_text = ref(t('general.copy_code'));
const input_type = ref(null);
const is_focused = ref(false);
const is_inside_input = ref(false);

const focusStakeInput = () => {
	is_focused.value = true;
	input_type.value = 'multiple_stake';
	if (betslipStore.betslip.multiple_stake == 0) betslipStore.betslip.multiple_stake = '';
}

const focusOutStakeInput = () => {
	// is_focused.value = false;
	if (betslipStore.betslip.multiple_stake == '') betslipStore.betslip.multiple_stake = 0;
}

const removeFromBetslip = (bet) => {
	betslipStore.removeFromBetslip({ odd_uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id })
}

const acceptChanges = () => {
	betslipStore.removeBetslipErrors();
}

const removeAllBets = () => {
	betslipStore.resetBetslip();
	betslipStore.toggleBetslip();
}

const goToFixture = (bet) => {
	if (bet.odd_info.live) {
		router.push({ name: 'live-sports-eventview', params: { fixture_id: bet.odd_info.fixture_id } });
		return;
	}

	router.push({ name: 'pre-sports-fixturebook', params: { sport_id: bet.odd_info.sport_id, region_id: bet.odd_info.region_id, competition_id: bet.odd_info.competition_id, fixture_id: bet.odd_info.fixture_id } });
}

const validateStake = (event) => {
	toggleCheckbox(true);
	if (event.key === ',') {
		event.preventDefault();
		if (!event.target.value.includes('.')) event.target.value += '.';
	}

	if (
		(event.key < '0' || event.key > '9') &&
		event.key !== '.' &&
		event.key !== 'Backspace'
	) {
		event.preventDefault();
	}

}

const toggleFreebet = (freebet) => {
	betslipStore.toggleFreebetOnMultiple(freebet);
}

const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() =>{});
}

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

const placeBetslip = () => {
	if (!customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	if (betslipStore.processing_placement) return;

	betslipStore.placeBetslip();
}

const calculateMultiplePrice = computed(() => {
	let price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			price = price * bet.odd_info.price;
		}
	}

	return price.toFixed(2);
});

const calculateMultipleTax = computed(() => {
	if (isNaN(betslipStore.betslip.multiple_stake)) return 0;
	let multiple_stake = Number(betslipStore.betslip.multiple_stake);

	let multiple_price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			multiple_price = multiple_price * bet.odd_info.price
		}
	}

	let multiple_return = 0;
	if (betslipStore.betslip.multiple_freebet_id) {
		multiple_return = (multiple_price * multiple_stake) - multiple_stake;
		return (multiple_return * betslipStore.betslip_tax);
	} else {
		multiple_return = multiple_price * Number(multiple_stake);
		return (multiple_return - multiple_stake) * betslipStore.betslip_tax;
	}
});

const calculateMultipleReturn = computed(() => {
	if (isNaN(betslipStore.betslip.multiple_stake)) return 0;
	let multiple_stake = Number(betslipStore.betslip.multiple_stake);

	let multiple_price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			multiple_price = multiple_price * bet.odd_info.price
		}
	}

	let tax_amount = 0;
	let multiple_return = 0;
	if (betslipStore.betslip.multiple_freebet_id) {
		multiple_return = (multiple_price * multiple_stake) - multiple_stake;
		if (betslipStore.betslip_tax) tax_amount = multiple_return * betslipStore.betslip_tax;
	} else {
		multiple_return = multiple_price * multiple_stake;
		if (betslipStore.betslip_tax) tax_amount = (multiple_return - betslipStore.betslip.multiple_stake) * betslipStore.betslip_tax;
	}

	return multiple_return - tax_amount;
});

onMounted(() => {
	betslipStore.validateSameFixtures();
})

watch(() => betslipStore.countBetslipBets, (newCount, oldCount) => {
	betslipStore.validateSameFixtures();
})

const shareBetslip = () => {
	let data = [];
	loading.value = true;

	for (const index in betslipStore.betslip.bets) {
		const bet = betslipStore.betslip.bets[index];

		data.push({
			bet_uuid: bet.odd_info.uuid,
			fixture_id: bet.odd_info.fixture_id
		});
	};

	sharedBetslipStore.storeSharedBetslip(JSON.stringify(data)).then((response) => {
		loading.value = false;
		share_url.value = response.message;
		share_id.value = response.code;
	}).catch((error) => {
		loading.value = false;
	})
}

const copyToClipboard = () => {
	navigator.clipboard.writeText(share_url.value);
	share_url_text.value = t('general.copied')

	setTimeout(() => {
		share_url_text.value = t('general.copy_url');
	}, 2000);
}

const copyIdToClipboard = () => {
	navigator.clipboard.writeText(share_id.value);
	share_id_text.value = t('general.copied')

	setTimeout(() => {
		share_id_text.value = t('general.copy_code');
	}, 2000);
}

const updateBetPrice = (type, value) => {
	toggleCheckbox(true);
	betslipStore.betslip.multiple_stake = betslipStore.validateInput(betslipStore.betslip.multiple_stake ? betslipStore.betslip.multiple_stake : '', type, value);
}

const isChecked = ref(false);
const isDropdownCheck = ref(false);
const isTooltip = ref(false);
const TooltipOpen = () => {
	isTooltip.value = !isTooltip.value;
}

// Function to toggle the checkbox state
const toggleCheckbox = (keypress = false) => {
	if (keypress) {
		const checkbox = document.getElementById('multiple-toggle-input');
		if (checkbox && checkbox.checked) {
			checkbox.checked = false;
			betslipStore.removeFreebet();
			isChecked.value = false;
		}
	} else {
		if (isChecked.value) {
			betslipStore.removeFreebet();
		}
		isChecked.value = !isChecked.value;
	}
}

const dropdownOpen = () => {
	isDropdownCheck.value = !isDropdownCheck.value;
}

const selectItem = (value) => {
	const defaultText = document.querySelector('#freebet_dropdown_multi .default-value p');
	if (defaultText) {
		defaultText.textContent = value;
		defaultText.classList.add('selected');
	}
	isDropdownCheck.value = false; // Close dropdown
}

const closeDropdown = () => {
	isDropdownCheck.value = false;
}

document.addEventListener('click', (event) => {
	// Check if the clicked element is inside the dropdown
	const dropdown = document.querySelector('#freebet_dropdown_multi');
	const tooltip = document.querySelector('.tooltip-outer'); // Change '.tooltip' to your tooltip selector
	const targetElement = event.target;

	if (dropdown && !dropdown.contains(targetElement)) {
		// Clicked outside of the dropdown, close it
		closeDropdown();
	}

	// Check if tooltip exists and clicked outside of it
	if (tooltip && !tooltip.contains(targetElement)) {
		// Clicked outside of the tooltip, close it
		isTooltip.value = false;
	}
});

const getMaxBet = () => {
	if (!customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	let bet_uuids = [];
	for (const key in betslipStore.betslip.bets) {
		const bet = betslipStore.betslip.bets[key];
		bet_uuids.push({uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id});
	}

	let payload = {};
	payload.bets = bet_uuids
	payload.type = 'multiple';

	betslipStore.calculateMaxBet(payload).then(response => {
		betslipStore.betslip.multiple_stake = response.max_bet;
	}).catch(error => {
		console.log(error);
	});
}

</script>
<template>
	<div class="betslip-beting-area">
	<div class="inner-tab-content">
		<div class="share-grid">
			<div class="share-inner-grid">
				<button v-if="share_url" class="share-btn" type="button" @click="copyToClipboard()">
					<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_url_text }}
				</button>
				<button v-if="share_id" class="share-btn" type="button" @click="copyIdToClipboard()">
					<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_id_text }}
				</button>
			</div>
			<button class="share-btn" type="button" @click="shareBetslip()" v-if="!loading">
				<i class="fa fa-share" aria-hidden="true"></i>&nbsp;{{ $t('general.share') }}
			</button>
			<div v-else class="loader-main"></div>
		</div>
		<div v-for="bet in betslipStore.betslip.bets" :key="bet.odd_info.uuid" class="bets_body">
			<div class="over_row" :style="bet.has_error ? 'display: grid; grid-template-columns: auto 1fr auto;' : '' " :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
				<div class="circle" v-if="bet.has_error">
					<img v-if="bet.has_error" src="/assets/images/pink_circle_img.png" alt="icon">
				</div>
				<div class="left">
					<div class="top_content"  :style="!bet.odd_info.live ? '' : ''">
						<div class="live-badge" v-if="bet.odd_info.live" v-html="svg_data.live_badge"></div>
						<div class="over">
							<p>{{ bet.odd_info.odd_name_translations[languagesStore.selectedLanguage.locale] }}</p>
						</div>
					</div>
					<div class="subtitle">
						<p>{{ bet.odd_info.market_name_translations[languagesStore.selectedLanguage.locale] }}</p>
					</div>
				</div>
				<div class="right">
					<div class="value" :class="{'betup': bet.odd_info.price > bet.price, 'betdown': bet.odd_info.price < bet.price}">
						<p>{{ bet.odd_info.sp ? bet.odd_info.placeholder : oddsStore.oddsFormat(bet.odd_info.price) }}</p>
					</div>
					<span class="seprater"></span>
					<div class="clear-ico betslip-button" @click="removeFromBetslip(bet)">
						<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.741" viewBox="0 0 12 14.741">
							<g id="Group_1850" data-name="Group 1850" transform="translate(0.5 0.5)">
								<g id="delete" transform="translate(0)">
								<path id="Path_896" data-name="Path 896" d="M176,21.08A1.08,1.08,0,0,1,177.08,20h2.161a1.08,1.08,0,0,1,1.08,1.08" transform="translate(-172.507 -20)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
								<line id="Line_157" data-name="Line 157" x2="11" transform="translate(0 3.143)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
								<g id="Path_897" data-name="Path 897" transform="translate(-94.69 -135.265)" fill="none" stroke-linecap="round" stroke-miterlimit="10">
									<path d="M96,140l1.079,8.56a1.074,1.074,0,0,0,1.063.946h4.283a1.074,1.074,0,0,0,1.063-.946l1.079-8.56Z" stroke="none"/>
									<path d="M 97.13394165039062 141.0000152587891 L 98.07138824462891 148.4382934570312 C 98.07550048828125 148.4717102050781 98.10279846191406 148.5062866210938 98.14138793945312 148.5062866210938 L 102.4241638183594 148.5062866210938 C 102.4627304077148 148.5062866210938 102.4900283813477 148.4717102050781 102.4945220947266 148.4352569580078 L 103.4315795898438 141.0000152587891 L 97.13394165039062 141.0000152587891 M 96 140.0000152587891 L 104.5655212402344 140.0000152587891 L 103.4866714477539 148.560302734375 C 103.4211196899414 149.0935516357422 102.9704132080078 149.5062866210938 102.4241638183594 149.5062866210938 L 98.14138793945312 149.5062866210938 C 97.59510803222656 149.5062866210938 97.1444091796875 149.0935516357422 97.078857421875 148.560302734375 L 96 140.0000152587891 Z" stroke="none" fill="#dbe1e6"/>
								</g>
								<g id="Group_1823" data-name="Group 1823" transform="translate(4.804 7.854)">
									<line id="Line_158" data-name="Line 158" y1="3.165" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
									<line id="Line_159" data-name="Line 159" y1="3.165" transform="translate(1.571)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
								</g>
								</g>
							</g>
						</svg>
					</div>
				</div>
			</div>
			<div class="bottom_row">
				<!-- <div class="left" :class="{'suspended': betslipStore.processing_placement }" style="cursor:pointer;" @click="removeFromBetslip(bet)">
					<div class="img" v-html="svg_data.close"> </div>
				</div> -->
				<div class="right" @click="goToFixture(bet)">
					<div class="full_detail" :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
						<div class="icon">
							<img :src="preSportsStore.preSportWhiteIcon(bet.odd_info.sport_id)" alt="card-image">
						</div>
					<div class="value">
						<div class="title_top">
							<p>{{ bet.odd_info.region_name_translations[languagesStore.selectedLanguage.locale] }} - {{ bet.odd_info.competition_name_translations[languagesStore.selectedLanguage.locale] }}</p>
						</div>
						<div v-if="liveSportsStore.hasOneParticipant(bet.odd_info.sport_id) || bet.odd_info.fixture_outright" class="title_bottom">
							<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }}</p>
								</div>
							<div v-else class="title_bottom">
								<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }} V {{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][1] }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="target-all">
			<div class="row">
				<div class="title">
					<p v-if="betslipStore.countBetslipBets == 2">{{ $t('betslip.doubles') }} ({{ betslipStore.countBetslipBets }})</p>
					<p v-if="betslipStore.countBetslipBets == 3">{{ $t('betslip.trebles') }} ({{ betslipStore.countBetslipBets }})</p>
					<p v-if="betslipStore.countBetslipBets > 3">{{ betslipStore.countBetslipBets }} {{ $t('betslip.folds') }}</p>
				</div>
				<div class="right_content_85 stake-input multiple-bets">
					<div class="flex-container">
						<p v-if="!betslipStore.hasSPOdd" class="betslip-price">@ {{ calculateMultiplePrice }}</p>
						<div class="odd-input">
							<span class="euro-icon">{{ currenciesStore.getCurrencySymbol }}</span>
							<input type="text" @keypress="validateStake" @focus="focusStakeInput" @focusout="focusOutStakeInput" v-model="betslipStore.betslip.multiple_stake" inputmode="none" autocapitalize="off" autocomplete="off" autocorrect="off" min="0" class="pay_btn"/>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- amount select start-->
		<div class="amount-select-main" :class={system_bet_numpad:is_focused}>
				<div class="amount-select-outer">
					<div class="amount-select-topbar">
						<div class="amount-selet-item">
							<p @click="updateBetPrice('add', 5)">+5</p>
						</div>
						<div class="amount-selet-item">
							<p @click="updateBetPrice('add', 10)">+10</p>
						</div>
						<div class="amount-selet-item">
							<p @click="updateBetPrice('add', 20)">+20</p>
						</div>
						<div class="amount-selet-item">
							<p @click="getMaxBet()">Max</p>
						</div>
					</div>
					<div class="amount-select-bottom" >
						<div class="amount-item" @click="updateBetPrice('append', 1)">
							<p >1</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 2)">
							<p>2</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 3)">
							<p>3</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 4)">
							<p>4</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 5)">
							<p>5</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 6)">
							<p>6</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 7)">
							<p>7</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 8)">
							<p>8</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 9)">
							<p>9</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', '.')">
							<p>.</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 0)">
							<p>0</p>
						</div>
						<div class="amount-item"  @click="updateBetPrice('delete', 0)">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
								<g id="Group_1851" data-name="Group 1851" transform="translate(-160 -502)">
									<path id="Path_898" data-name="Path 898" d="M0,0H15V14H0L-5,7Z" transform="translate(165 502)" fill="#202833"/>
									<path id="Path_899" data-name="Path 899" d="M.5-3.354,2.464-6.071H1.45L.007-4.03-1.45-6.071H-2.464l1.976,2.73L-2.906,0h.988L.007-2.652,1.9,0h1Z" transform="translate(171.905 512.071)" fill="#fff"/>
								</g>
							</svg>

						</div>
					</div>
				</div>
		</div>
		<!-- amount select end -->

		</div>

		<!-- STart -->
		<template v-if="betslipStore.multipleBetslipEligibleFreebets.length > 0">
			<div class="freebet-main">
				<div class="freebet-inner">
					<div class="left">
						<p>{{ $t('betslip.freebet') }}</p>
					</div>
					<div class="right">
						<div class="toggle-btn">
							<label class="switch">
								<input type="checkbox" id="multiple-toggle-input" @click="toggleCheckbox(false)">
								<span class="toggle-slider round"></span>
							</label>
						</div>
					</div>
				</div>

				<div class="freebet-inner freebet-amount-inner" :class="{ 'freebet-inner-show': isChecked }">
					<div class="left">
						<p>{{ $t('betslip.select_your_freebet') }}</p>
					</div>
					<div class="right">
						<div class="dropdown-input"  id="freebet_dropdown_multi" :class="{ 'dropdown-open': isDropdownCheck }">
							<div class="default-value" @click="dropdownOpen">
								<p>{{ $t('betslip.select') }}</p>
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414" viewBox="0 0 11.414 6.414">
									<g id="Group_227" data-name="Group 227" transform="translate(-1662.793 -579.793)">
										<line id="Line_5" data-name="Line 5" x2="5.068" y2="5" transform="translate(1663.5 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
										<line id="Line_6" data-name="Line 6" x1="5.068" y2="5" transform="translate(1668.432 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
									</g>
									</svg>
								</span>
							</div>
							<div class="dropdown-inner-items" >
								<div class="dropdown-item" v-for="freebet in betslipStore.multipleBetslipEligibleFreebets" :key="freebet.id" @click="selectItem(currenciesStore.format_currency(freebet.amount));toggleFreebet(freebet);">
									<p>{{currenciesStore.format_currency(freebet.amount)}}</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</template>
		<!-- End -->
	<div class="bets_body_bottom">
		<template v-if="!betslipStore.hasSPOdd">
			<div v-if="betslipStore.betslip_tax > 0" class="row">
				<div class="title">
					<p>{{$t('betslip.tax')}}</p>
				</div>

				<div class="right_content_85 light">
					<p>{{ currenciesStore.format_currency(calculateMultipleTax) }}</p>
				</div>
			</div>

			<div class="row total-bet">
				<div class="title">
					<p>{{$t('betslip.potential_return')}}</p>
				</div>

				<div class="right_content_85 text-white">
					<p>{{ currenciesStore.format_currency(calculateMultipleReturn) }}</p>
				</div>
			</div>
		</template>

		<div class="row_1">
			<template v-if="betslipStore.countBetslipBets == 50">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ $t('betslip.bet_limit_exceeded') }}</p>
					</div>
				</div>
			</template>
			<template v-if="betslipStore.same_fixture_error_message">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ $t('betslip.bets_can_not_combined') }}</p>
					</div>
				</div>
			</template>

			<template v-else>
				<template v-if="betslipStore.betslip.error_message">
					<div class="error_wrap">
						<div class="detail">
							<p>{{ betslipStore.betslip.error_message }}</p>
						</div>
					</div>
					<a @click="acceptChanges" href="#" class="btn-small btn-secondary betslip-button">{{$t('betslip.accect_changes')}}</a>
				</template>
				<template v-else>
					<a @click="placeBetslip" href="#" class="btn-small btn-secondary betslip-button">
						{{ $t('betslip.place_bet') }} {{ currenciesStore.format_currency(betslipStore.betslip.multiple_stake) }}
						<div v-if="betslipStore.processing_placement" class="lds-ring"><div></div><div></div><div></div><div></div></div>
					</a>
				</template>
			</template>
		</div>
	</div>
</template>
<style scoped>
.suspended {
	opacity: 0.2;
	pointer-events: none;
	cursor: not-allowed;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 15px;
	height: 15px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 2px;
	margin-right: 10px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

html[dir="rtl"] .eventime_mobal_content .inner-tab-content .bets_body .over_row .right .value {
	text-align: left;
}

.share-grid {
	display: grid;
	margin-top: 8px;
	padding-bottom: 8px;
	grid-template-columns: auto 102px;
	gap: 3%;
	align-items: center;
	border-bottom: 1px solid #4E545D;
	justify-items: center;
}

.share-inner-grid {
	display: grid;
	grid-template-columns: 50% 50%;
	gap: 3%;
	justify-content: space-evenly;
}

.share-url {
	color: #fff;
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
	text-wrap: nowrap;
}
.share-btn {
	background: #FFBB02;
	color: #202833;
	border: 1px solid transparent;
	height: 20px;
	transition: 0.3s ease-in-out;
	border-radius: 4px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	transition: 0.3s ease-in-out;
	padding: 0px 5px 5px 5px;
	width: 100px;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.share-btn:hover {
	border: 1px solid #878A90;
	background: #131C27;
	color: #fff;
}

.disabled-button {
	background: transparent;
	pointer-events: none;
	border: 1px solid #4E545D;
	color: #4E545D;
}
.loader-main {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: relative;
}

.register-input-check {
	padding: 4px 0px 0;
	display: flex;
	align-items: flex-start;
	gap: 8px;
}

.register-input-check input[type=checkbox] {
	-webkit-appearance: checkbox !important;
	-moz-appearance: checkbox !important;
	appearance: checkbox !important;
	margin-top: 4px;
}

.check-dash {
	position: absolute;
	opacity: 0;
}

.check-dash + label {
	position: relative;
	padding: 0;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	gap: 3%;
	width: 100%;
}

.register-input-check p {
	color: #fff;
	font-size: 12px;
	text-align: start;
	cursor: pointer;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.register-input-check .check-dash + label:before {
	content: "";
	margin-right: 0px;
	display: inline-block;
	vertical-align: text-top;
	width: 16px;
	height: 16px;
	background: transparent;
	outline: 1px solid #878A90;
	border-radius: 3px;
	margin-top: 1px;
	cursor: pointer;
}
.register-input-check .check-dash:checked + label:after {
	content: "";
	position: absolute;
	left: 6px;
	top: 4px;
	display: inline-block;
	transform: rotate(45deg);
	height: 9px;
	width: 4px;
	border-bottom: 1px solid #63A246;
	border-right: 1px solid #63A246;
}
html[dir="rtl"] .register-input-check .check-dash:checked + label:after {
	right: 6px;
	left: unset;
}

.dropdown-inner-items {
	z-index: 999 !important;
	overflow: auto !important;
	max-height: 100px;
	height: auto;
}
</style>