<script setup>
/* eslint-disable */
import { onMounted, onUnmounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useInitializeStore } from '@/stores/initialize';
import { useSocketStore } from '@/stores/socket';
import { useFixtureStore } from '@/stores/fixture';
import { useVirtualCourtStore } from '@/stores/virtual_court';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useGeneralStore } from '@/stores/general';

import LeftSidebarOverview from '@/components/live_sports/LeftSidebarOverview.vue';
import LiveSportsFixtureBook from '@/components/live_sports/LiveSportsFixtureBook.vue';
import VirtualCourt from '@/components/live_sports/VirtualCourt.vue'
import VirtualCourtNew from '@/components/live_sports/VirtualCourtNew.vue'
import Promotions from '@/components/live_sports/Promotions.vue';

const router = useRouter();
const route = useRoute();
const initializeStore = useInitializeStore();
const socketStore = useSocketStore();
const fixtureStore = useFixtureStore();
const liveSportOverviewStore = useLiveSportOverview();
const liveSportsStore = useLiveSportsStore();
const virtualCourtStore = useVirtualCourtStore();
const generalStore = useGeneralStore();

socketStore.getSocket.on('liveFixtureBook', (data) => {
	fixtureStore.setFixture(data);
	fixtureStore.setFixtureLoading(false);

	setPageTitle();

	liveSportsStore.setSelectedLiveSportId(fixtureStore.fixtureBook.sport_id);
	liveSportsStore.selectFavourites(false);

	virtualCourtStore.setFixtureInQueue(fixtureStore.fixtureBook);
})

socketStore.getSocket.on('liveFixtureBookNotFound', (data) => {
	fixtureStore.setFixtureLoading(false);
	router.push({ name: 'not-found' });
})

socketStore.getSocket.on('liveFixtureBookUpdate', (data) => {
	fixtureStore.updateFixture(data);
})

socketStore.getSocket.on('liveFixtureBookDelete', (data) => {
	fixtureStore.emptyFixture(data);
	router.push({ name: 'live-sports-overview' });
})

const setPageTitle = () => {
	if (liveSportsStore.hasOneParticipant(fixtureStore.fixture.sport_id) || fixtureStore.fixture.outright == true) {
		document.title = generalStore.getGeneral.site_name + ' - ' + fixtureStore.fixture.participants[0];
		return;
	}

	document.title = generalStore.getGeneral.site_name + ' - ' + fixtureStore.fixture.participants[0] + ' - ' + fixtureStore.fixture.participants[1];
};

onMounted(() => {
	let fixture_id = router.currentRoute.value.params.fixture_id;

	// Join FixtureBook socket chanel
	fixtureStore.setFixtureLoading(true);
	socketStore.getSocket.emit('join-liveFixtureBook', fixture_id);
})

onUnmounted(() => {
	socketStore.getSocket.emit('leave-liveFixtureBook', fixtureStore.fixtureBook.id);
	fixtureStore.emptyFixture();
})

// watch(() => liveSportsStore.selected_live_sport_id, (newSportId, oldSportId) => {
// 	if (newSportId > 0) {
// 		socketStore.getSocket.emit('join-liveSportOverview', newSportId);
// 		overview_market.value = sport_overview_markets[0];
// 	}
// })

watch(route, (to, from) => {
	if (!to.params.hasOwnProperty('fixture_id')) return;

	if (fixtureStore.fixtureBook.id == to.params.fixture_id) return;

	socketStore.getSocket.emit('leave-liveFixtureBook', fixtureStore.fixtureBook.id);
	fixtureStore.emptyFixture();

	fixtureStore.setFixtureLoading(true);
	socketStore.getSocket.emit('join-liveFixtureBook', to.params.fixture_id);

});

</script>
<template>
<div id="eventview" class="tabcontent">
	<div class="sports-main-outer eventview-pannel" :class="fixtureStore.mobile_active_tab == 'scoreboard' ? 'mobile-scoreboard-active' : '' ">
		<div class="sports-outer-left">
			<div class="sports-left-sidebar">
				<LeftSidebarOverview/>
			</div>
			<div class="sports-center-content">
				<LiveSportsFixtureBook/>
				<!-- <Eventview v-model="active_sport_id" :fixture_id="fixture_id" @update:fixture_id="newValue => fixture_id = newValue" :mobile_active_tab="mobile_active_tab" :active_fixture_name="active_fixture_name" @update:active_fixture_name="newValue => active_fixture_name = newValue" ></Eventview> -->
			</div>
		</div>
		<div class="sports-outer-right">
			<!-- live sport right EventsLiveEvents start -->
			<div style="margin-top: 25px;" :style="fixtureStore.mobile_active_tab == 'scoreboard' && initializeStore.screenWidth < 1025 ? 'visibility:hidden; height: 0px;' : 'visibility: visible'">
				<VirtualCourt v-if="!initializeStore.betcomCourtEnabled"></VirtualCourt>
				<VirtualCourtNew v-else ></VirtualCourtNew>
			</div>
			<!-- live sport right EventsLiveEvents end -->

			<div class="sports-promotion-outer">
				<div class="promotion-header">
					<h2>{{ $t('general.promotions') }}</h2>
				</div>
				<Promotions></Promotions>
			</div>

		</div>

	</div>
</div>
</template>
<style scoped>
@media screen and (min-width: 1281px) and (max-width: 1359px) {
	.sports-main .sports-main-outer {
		grid-template-columns: 5fr 20% !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.sports-main .eventview-pannel .sports-center-content {
		min-height: 500px;
	}
	.sports-main .eventview-pannel {
		padding-bottom: unset;
	}
}

.sports-promotion-outer {
	max-width: 98vw !important;
}

@media screen and (min-width: 1026px) and (max-width: 1277px) {
	.sports-promotion-outer {
		margin-top: 0px !important;
		padding: 0 !important;
		border: 0 !important;
	}
}

html[dir="rtl"] .tabcontent {
	padding-right: 20px;
}

@media screen and (min-width: 992px) and (max-width: 1190px) {
	.sports-main .eventview-pannel .sports-outer-left {
		grid-template-columns: 200px 1fr;
	}
}
</style>
