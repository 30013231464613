<script setup>
/* eslint-disable */
import { computed } from 'vue';
import { BetSlip } from '@/stores/BetSlip';
import { useLanguagesStore } from '@/stores/languages';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useCurrenciesStore } from '@/stores/currencies';
import * as Combinatorics from 'js-combinatorics';
import { useOddsStore } from '@/stores/odds';

const oddsStore = useOddsStore();
const betslipStore = BetSlip();
const languagesStore = useLanguagesStore();
const liveSportsStore = useLiveSportsStore();
const currenciesStore = useCurrenciesStore();

const resetAndCloseBetslip = () => {
	betslipStore.resetBetslip();
	betslipStore.$patch((state) => {
		state.betSlipModel='';
	});
}

const keepBetslip = () => {
	betslipStore.keepBetslip();
}

const calculateMultiplePrice = computed(() => {
	let price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			price = price * bet.odd_info.price
		}
	}

	return price.toFixed(2);
});

const calculateSystemMultipleReturnTax = computed(() => {
	if (isNaN(betslipStore.betslip.system_multiple_stake)) return 0;
	let multiple_stake = Number(betslipStore.betslip.system_multiple_stake);

	let multiple_price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			multiple_price = multiple_price * bet.odd_info.price
		}
	}

	let multiple_return = 0;
	if (betslipStore.betslip.multiple_freebet_id) {
		multiple_return = (multiple_price * multiple_stake) - multiple_stake;
		return (multiple_return * betslipStore.betslip_tax);
	} else {
		multiple_return = multiple_price * Number(multiple_stake);
		return (multiple_return - multiple_stake) * betslipStore.betslip_tax;
	}
});

const calculateSystemMultipleReturn = computed(() => {
	if (isNaN(betslipStore.betslip.system_multiple_stake)) return 0;
	let multiple_stake = Number(betslipStore.betslip.system_multiple_stake);

	let multiple_price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			multiple_price = multiple_price * bet.odd_info.price
		}
	}

	let tax_amount = 0;
	let multiple_return = 0;
	if (betslipStore.betslip.multiple_freebet_id) {
		multiple_return = (multiple_price * multiple_stake) - multiple_stake;
		if (betslipStore.betslip_tax) tax_amount = multiple_return * betslipStore.betslip_tax;
	} else {
		multiple_return = multiple_price * multiple_stake;
		if (betslipStore.betslip_tax) tax_amount = (multiple_return - betslipStore.betslip.multiple_stake) * betslipStore.betslip_tax;
	}

	return multiple_return - tax_amount;
});

const calculateSinglesTax = computed(() => {
	let tax_amount = 0;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			let bet_return = bet.odd_info.price * betslipStore.betslip.system_singles_stake;
			tax_amount += bet_return * betslipStore.betslip_tax;

		}
	}

	return tax_amount;
});

const calculateSinglesReturn = computed(() => {

	let possible_return = 0;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			let bet_return = bet.odd_info.price * betslipStore.betslip.system_singles_stake;
			let tax_amount = bet_return * betslipStore.betslip_tax;

			possible_return += bet_return - tax_amount;
		}
	}

	return possible_return;
});

const calculateSystemStake = (system_size) => {
	let system_stake = betslipStore.betslip['systemX' + system_size + '_stake'];
	let combinations = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), system_size);

	system_stake = Number(system_stake) * Number(combinations.length);

	return system_stake;
}

const calculateSystemReturnTax = (system_size) => {
	if (isNaN(betslipStore.betslip['systemX' + system_size + '_stake'])) return 0;

	let combinations = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), system_size);

	let total_stakes = 0;
	let return_possible = 0;
	[...combinations].forEach(combination => {
		let system_price = 1;
		combination.forEach(bet => {
			system_price = system_price * bet.odd_info.price;
		})

		total_stakes += Number(betslipStore.betslip['systemX' + system_size + '_stake']);
		return_possible += Number(betslipStore.betslip['systemX' + system_size + '_stake']) * system_price;
	});

	return (return_possible - total_stakes) * betslipStore.betslip_tax;
}

const calculateSystemReturn = (system_size) => {
	if (isNaN(betslipStore.betslip['systemX' + system_size + '_stake'])) return 0;

	let combinations = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), system_size);

	let total_stakes = 0;
	let return_possible = 0;
	[...combinations].forEach(combination => {
		let system_price = 1;
		combination.forEach(bet => {
			system_price = system_price * bet.odd_info.price;

		})

		total_stakes += Number(betslipStore.betslip['systemX' + system_size + '_stake']);
		return_possible += Number(betslipStore.betslip['systemX' + system_size + '_stake']) * system_price;
	});

	let tax_amount = 0;
	if (betslipStore.betslip_tax) tax_amount = (return_possible - total_stakes) * betslipStore.betslip_tax;

	return return_possible - tax_amount;
}

const betsToShow = computed(() => {
	if (betslipStore.betslip.type != 'singles') return betslipStore.betslip.bets;

	let bets_to_show = _.filter(betslipStore.betslip.bets, function(bet) {
		if (Number(bet.stake > 0)) return true;
		return false;
	});

	return bets_to_show
});
</script>

<template>
	<div class="bet_slip_wrap tab-content active open " id="tab1">
		<!-- betslip Receipt -->
		<div class="br-betslip-receipt">
			<h6 class="br-h6">
				<svg xmlns="http://www.w3.org/2000/svg" width="14.121" height="10.061" viewBox="0 0 14.121 10.061">
					<path id="Icon_feather-check" data-name="Icon feather-check" d="M14.333,3.5l-8.25,8.25L2.333,8" transform="translate(-1.273 -2.439)" fill="none" stroke="#63A246" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
				</svg>
				{{$t('betslip.bet_placed')}}
			</h6>
			<div class="bets-container">
				<div class="row-br-box">
					<!-- betslip Receipt box-->
					<div v-for="(bet, index) in betsToShow" :key="index" class="col-br-box">
						<div class="brb-title">{{ bet.odd_info.region_name_translations[languagesStore.selectedLanguage.locale] }} - {{ bet.odd_info.competition_name_translations[languagesStore.selectedLanguage.locale] }}  </div>
						<div class="brb-details">
							<div>
								<img class="brbd-icon" :src="liveSportsStore.liveSportWhiteIcon(bet.odd_info.sport_id)" alt="football-icon">
							</div>
							<div class="brbd-des">
								<p v-if="liveSportsStore.hasOneParticipant(bet.odd_info.sport_id) || bet.odd_info.fixture_outright">{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }}</p>
								<p v-else>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }} V {{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][1] }}</p>
							</div>
						</div>
						<div class="ft-result">
							<span class="ftr-title">{{ bet.odd_info.market_name_translations[languagesStore.selectedLanguage.locale] }} </span>
							<div class="ftr-btm">
								<span>{{ bet.odd_info.odd_name_translations[languagesStore.selectedLanguage.locale] }}</span>
								<span class="ftr-btm-right">{{ bet.odd_info.sp ? bet.odd_info.placeholder : oddsStore.oddsFormat(bet.odd_info.placed_price) }}</span>
							</div>
						</div>
					</div>

				</div>
				<div v-if="betslipStore.betslip.type == 'singles'" class="row-stake-return">
					<div class="col-sreturn rsr-first">
						<span>{{$t('betslip.stake')}}:</span>
						<span>{{ currenciesStore.format_currency(betslipStore.betslip.total_stake) }}</span>
					</div>
					<template v-if="!betslipStore.hasSPOdd">
						<div v-if="betslipStore.betslip_tax > 0" class="col-sreturn rsr-first">
							<span>{{$t('betslip.tax')}}:</span>
							<span>{{ currenciesStore.format_currency(betslipStore.betslip.tax_amount) }}</span>
						</div>
						<div class="col-sreturn rsr-third">
							<span class="font13">{{$t('betslip.to_return')}}:</span>
							<span class="font14">{{ currenciesStore.format_currency(betslipStore.betslip.total_return) }}</span>
						</div>
					</template>
				</div>

				<div v-if="betslipStore.betslip.type == 'multiple'" class="row-stake-return">
					<div class="col-sreturn rsr-first">
						<span>{{$t('betslip.stake')}}:</span>
						<span>{{ currenciesStore.format_currency(betslipStore.betslip.total_stake) }}</span>
					</div>
					<div v-if="betslipStore.betslip_tax > 0 && !betslipStore.hasSPOdd" class="col-sreturn rsr-first">
						<span>{{$t('betslip.tax')}}:</span>
						<span>{{ currenciesStore.format_currency(betslipStore.betslip.tax_amount) }}</span>
					</div>
					<div class="col-sreturn rsr-second">
						<span v-if="betslipStore.countBetslipBets == 2">{{$t('betslip.doubles')}}:</span>
						<span v-if="betslipStore.countBetslipBets == 3">{{$t('betslip.trebles')}}:</span>
						<span v-if="betslipStore.countBetslipBets >= 4">{{ betslipStore.countBetslipBets }} {{$t('betslip.folds')}}:</span>
						<span v-if="!betslipStore.hasSPOdd" class="text-green">{{ calculateMultiplePrice }}</span>
					</div>
					<div v-if="!betslipStore.hasSPOdd" class="col-sreturn rsr-third">
						<span class="font13">{{$t('betslip.to_return')}}:</span>
						<span class="font14">{{ currenciesStore.format_currency(betslipStore.betslip.total_return) }}</span>
					</div>

				</div>
				<template v-if="betslipStore.countBetslipBets > 2 && betslipStore.betslip.type == 'system'">
					<!-- Singles System -->
					<div v-if="betslipStore.betslip.system_singles_stake > 0" class="row-stake-return">
						<div class="col-sreturn rsr-first">
							<span>{{ $t('betslip.singles') }}</span>
							<span>{{ currenciesStore.format_currency(betslipStore.betslip.system_singles_stake * Object.keys(betslipStore.betslip.bets).length) }}</span>
						</div>
						<div v-if="betslipStore.betslip_tax > 0 && !betslipStore.hasSPOdd" class="col-sreturn rsr-first">
							<span>{{ $t('betslip.tax') }}:</span>
							<span>{{ currenciesStore.format_currency(calculateSinglesTax) }}</span>
						</div>

						<div v-if="!betslipStore.hasSPOdd" class="col-sreturn rsr-third">
							<span class="font13">{{ $t('betslip.to_return') }}:</span>
							<span class="font14">{{ currenciesStore.format_currency(calculateSinglesReturn) }}</span>
						</div>
					</div>

					<template v-for="(row, index) in (betslipStore.countBetslipBets - 2)" :key="index">
						<div v-if="betslipStore.betslip['systemX' + (index + 2) + '_stake'] > 0" class="row-stake-return">
							<div class="col-sreturn rsr-first">
								<span>{{ $t('betslip.stake_system') }} X{{ index + 2 }}</span>
								<span>{{ currenciesStore.format_currency(calculateSystemStake(index + 2)) }}</span>
							</div>

							<template v-if="!betslipStore.hasSPOdd">
								<div v-if="betslipStore.betslip_tax > 0" class="col-sreturn rsr-first">
									<span>{{ $t('betslip.tax') }}:</span>
									<span>{{ currenciesStore.format_currency(calculateSystemReturnTax(index + 2)) }}</span>
								</div>

								<div class="col-sreturn rsr-third">
									<span class="font13">{{ $t('betslip.to_return') }}:</span>
									<span class="font14">{{ currenciesStore.format_currency(calculateSystemReturn(index + 2)) }}</span>
								</div>
							</template>
						</div>
					</template>

					<div v-if="betslipStore.betslip.system_multiple_stake > 0" class="row-stake-return">
						<div class="col-sreturn rsr-first">
							<span v-if="betslipStore.countBetslipBets == 2">{{ $t('betslip.doubles') }}</span>
							<span v-if="betslipStore.countBetslipBets == 3">{{ $t('betslip.trebles') }}</span>
							<span v-if="betslipStore.countBetslipBets > 3">{{ betslipStore.countBetslipBets }} {{ $t('betslip.folds') }}</span>
							<span>{{ currenciesStore.format_currency(betslipStore.betslip.system_multiple_stake) }}</span>
						</div>

						<template v-if="!betslipStore.hasSPOdd">
							<div v-if="betslipStore.betslip_tax > 0" class="col-sreturn rsr-first">
								<span>{{ $t('betslip.tax') }}:</span>
								<span>{{ currenciesStore.format_currency(calculateSystemMultipleReturnTax) }}</span>
							</div>

							<div class="col-sreturn rsr-third">
								<span class="font13">{{ $t('betslip.to_return') }}:</span>
								<span class="font14">{{ currenciesStore.format_currency(calculateSystemMultipleReturn) }}</span>
							</div>
						</template>
					</div>

				</template>

			</div>
			<div class="row-br-total">
				<div class="col-br-total col-total-stake">
					<div class="cts-title">{{$t('betslip.total_stake')}}:</div>
					<div class="cts-total">{{ currenciesStore.format_currency(betslipStore.betslip.total_stake) }}</div>
				</div>
				<div v-if="!betslipStore.hasSPOdd" class="col-br-total">
					<div class="cts-title">{{$t('betslip.net_return')}}:</div>
					<div class="cts-total">{{ currenciesStore.format_currency(betslipStore.betslip.total_return) }}</div>
				</div>
			</div>
			<div class="br-cta">
				<a href="#!" class="btn-light-outline btn-small betslip-button" @click="keepBetslip"> {{$t('betslip.keep')}} </a>
				<a href="#!" class="btn-small btn-secondary betslip-button" @click="resetAndCloseBetslip"> {{$t('betslip.close')}} </a>
			</div>
		</div>
	</div>
</template>

<style scoped>
.bets-container {
	max-height: 600px;
	overflow-y: scroll;
}

.bets-container::-webkit-scrollbar {
	width: 3px;
}

.bets-container::-webkit-scrollbar-thumb {
	background-color: #8f54a0;
	border-radius: 2px;
	border: 3px solid linear-gradient(#e382bc 0%, #8d54eb 100%);
}

@media screen and (min-width: 320px) and (max-width: 767px){
	.bets-container {
		max-height: calc(100vh - 280px);
	}
}
</style>@/stores/BetSlip