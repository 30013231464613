/* eslint-disable */
import { defineStore } from 'pinia';
import _ from 'lodash';

export const useFooterSectionStore = defineStore('footer_section', {
	state: () => ({
		footer_section: [],
	}),
	getters: {
		getFooterSections(state) {
			console.log(state.footer_section)
			return state.footer_section;
		},
	},
	actions: {
		fetchFooterSections() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/footer-sections').then((response) => {
				this.footer_section.splice(0, this.footer_section.length, ...response.data);
				return Promise.resolve(response.data);
			}).catch((error) => {
				this.footer_section = 'not_found';
				return Promise.reject();
			});
		}
	},
})